import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState, withHooks } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ActivityService } from '@app-activities/data-access/infra/activities.service';
import { tapResponse } from "@ngrx/operators";
import { concatMap, iif, of, pipe, switchMap, tap } from 'rxjs';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GroupDash, IActivitySettings, IGroup, IGroupDash, IRequestChangeStatus } from '@app-activities/data-access/entities/activities.interface';
import { ECardStatus, ETime } from '@app-activities/data-access/entities/activities.enum';
import { DateTime } from 'luxon';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { PresentationStore } from '@app-presentation/data-access/presentation.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
export interface IActivityState {
    GroupsDash: IGroupDash;
    ReserveList: IGroupDash;
    ParticipantStatusState: Record<string, 'loaded' | 'loading' | 'error'>;
    ActivitySettings: IActivitySettings | null;
};

const initialState: IActivityState = {
    GroupsDash: new GroupDash({}),
    ReserveList: new GroupDash({}),
    ParticipantStatusState: {},
    ActivitySettings: null,
};

export const ActivityStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        presentationStore = inject(PresentationStore),
        activityService = inject(ActivityService),
        authStore = inject(AuthStore),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
    ) => {

        const canCheckinWithoutReservation = (group: IGroup, newStatus: ECardStatus) => {
            const oldStatus = group.ParticipantStatus;
            const activitySettings = store.ActivitySettings();
            if (!activitySettings) return false;

            const { AllowCheckinWithoutReservation, TimeBeforeCheckinWithoutReservation, TimeTypeBeforeCheckinWithoutReservation } = activitySettings;

            if (newStatus === ECardStatus.Confirmed && oldStatus !== ECardStatus.Reserved &&
                AllowCheckinWithoutReservation && TimeBeforeCheckinWithoutReservation) {

                const occurredOn = DateTime.fromISO(String(group.OccurredOn));
                const now = DateTime.now();
                const timeTypeName = ETime[TimeTypeBeforeCheckinWithoutReservation] as 'minute' | 'hour';
                const diffNow = occurredOn.diff(now).as(timeTypeName);

                if (diffNow > TimeBeforeCheckinWithoutReservation) {
                    alertStore.openAlert({
                        title: 'Check-in indisponível!',
                        message: `Só é possível fazer o check-in ${TimeBeforeCheckinWithoutReservation} ${TimeTypeBeforeCheckinWithoutReservation === ETime.hour ? 'horas' : 'minutos'} antes do início da aula.`,
                        type: EAlertTypes.WARNING,
                        mode: 'alert',
                    });
                    return false;
                }
            }
            return true;
        }

        const canCancelSchedule = (OccurredOn: string) => {
            const activitySettings = store.ActivitySettings();
            if (!activitySettings) return false;

            const { TimeLimitGroupCancellation, TimeTypeLimitGroupCancellation } = activitySettings;

            if (TimeLimitGroupCancellation) {
                const occurredOn = DateTime.fromISO(OccurredOn);
                const timeTypeName = ETime[TimeTypeLimitGroupCancellation] as 'minute' | 'hour';
                const diffNow = occurredOn.diff(DateTime.now()).as(timeTypeName);

                if (TimeLimitGroupCancellation > diffNow) {
                    alertStore.openAlert({
                        title: 'Cancelamento indisponível!',
                        message: `Só é possível cancelar o agendamento com ${TimeLimitGroupCancellation} ${TimeTypeLimitGroupCancellation === ETime.hour ? 'horas' : 'minutos'} de antecedência.`,
                        type: EAlertTypes.WARNING,
                        mode: 'alert',
                    });
                    return false;
                }
            }
            return true;
        },

        clearConfigGroupsDash = () => {
            patchState(store, { GroupsDash: new GroupDash({}) }, setPropInit('GroupsDash'));
        },

        clearConfigReserveList = () => {
            patchState(store, { ReserveList: new GroupDash({}) }, setPropInit('ReserveList'));
        }

        return {

            getActivitySettings: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('GetActivitySettings') })),
                switchMap(() => activityService.getActivitySettings().pipe(
                    tapResponse(
                        (response) => {
                            patchState(store, { ActivitySettings: response, ...setPropLoaded('GetActivitySettings') });
                        },
                        (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('GetActivitySettings', error) });
                        }
                    )
                ))
            )),

            getReserveList: rxMethod<Partial<IActivityState['GroupsDash']>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('ReserveList') })),
                concatMap(({ isOrderDescending, scrolling }) =>
                    activityService.getParticipationsByPersonId({
                        personId: String(authStore.getAuthData()?.PersonId),
                        startOn: DateTime.now().toISO({ includeOffset: false }),
                        take: 10,
                        skip: scrolling ? store.ReserveList().taked * 10 : 0,
                        isOrderDescending: isOrderDescending!
                    })
                    .pipe(
                        tap({
                            next: (response) => {
                                const personId = authStore.getAuthData()?.PersonId ?? '';
                                const reserveList = store?.ReserveList()?.setParticipantData(response, personId);
                                const isFinished = response.length < 10;
                                store.ReserveList().updateCurrentData(reserveList, isOrderDescending!, isFinished);
            
                                patchState(store, { ReserveList: store.ReserveList(), ...setPropLoaded('ReserveList') });
                            },
                        error: (error) => {
                          patchState(store, { ...setPropError('ReserveList', error) });
                          errorHandlerService.handleError(error);
                          presentationStore.reload(false);
                        }
                      })
                    )
                )
            )),

            getGroupsDash: rxMethod<Partial<IActivityState['GroupsDash']>>(pipe(
                tap(() => patchState(
                  store, setPropLoading('GroupsDash'))),
                  concatMap(({ startOn, endOn, isOrderDescending, scrolling }) =>
                    activityService.getClassesPlanned({
                        take: 10,
                        skip: scrolling ? store.GroupsDash().taked * 10 : 0,
                        startOn: startOn ?? DateTime.now().startOf('day').toISO({ includeOffset: false }),
                        endOn: endOn ?? DateTime.now().endOf('day').toISO({ includeOffset: false }),
                        isOrderDescending: isOrderDescending!
                    })
                    .pipe(
                        tap({
                            next: (response) => {
                                const personId = authStore.getAuthData()?.PersonId ?? '';
                                const groupsDash = store.GroupsDash().setParticipantData(response, personId);
                                const isFinished = response.length < 10;

                                store.GroupsDash().updateCurrentData(groupsDash, isOrderDescending!, isFinished);
                                patchState(store, { GroupsDash: store.GroupsDash(), ...setPropLoaded('GroupsDash') });
                            },
                        error: (error) => {
                          patchState(store, { ...setPropError('GroupsDash', error) });
                          errorHandlerService.handleError(error);
                          presentationStore.reload(false);
                        }
                      })
                    )
                )
            )),

            changeParticipantStatus: rxMethod<{ group: Partial<IGroup>, newStatus: ECardStatus }>(pipe(
                switchMap(({ group, newStatus }) => {
                    const payload: IRequestChangeStatus = {
                        PersonId: authStore.getAuthData()?.PersonId,
                        GroupId: group.GroupId!,
                        OccurredOn: group.OccurredOn!,
                        Status: newStatus,
                    }
                    const request = group.ParticipantId ? activityService.updateParticipant({ ...payload, ParticipantId: group.ParticipantId }) : activityService.createParticipant(payload);
                    return iif(
                        () => {
                            const canRequest = canCheckinWithoutReservation(group as IGroup, newStatus);
                            if (canRequest) patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'loading' }, ...setPropLoading('ChangeParticipantStatus') });
                            return canRequest;
                        },
                        request.pipe(
                            tapResponse({
                                next: ({ ParticipantId, ParticipantStatus, PersonId, PersonName }) => {
                                    group.ParticipantStatus = ParticipantStatus;
                                    if (!group.ParticipantId) {
                                        group.ParticipantId = ParticipantId;
                                        group.NumberVacanciesAvailable! -= 1;
                                        group.Participants?.push({ ParticipantId, ParticipantStatus, PersonId, Name: PersonName });
                                    }

                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'loaded' }, ...setPropLoaded('ChangeParticipantStatus') });
                                    alertStore.openAlert({
                                        title: ParticipantStatus === ECardStatus.Reserved ? 'Reserva realizada!' : 'Check-in realizado!',
                                        message: ParticipantStatus === ECardStatus.Reserved ? 'Lembre-se de fazer o check-in antes da aula começar.' : 'Seu check-in foi realizado com sucesso.',
                                        type: EAlertTypes.SUCCESS,
                                        mode: 'alert',
                                    });
                                },
                                error: (error: HttpErrorResponse) => {
                                    errorHandlerService.handleError(error);
                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'error' }, ...setPropError('ChangeParticipantStatus') });
                                }
                            }),
                        ),
                        of(null)
                    )
                })
            )),

            deleteParticipant: rxMethod<IGroup>(pipe(
                tap(() => patchState(
                    store, setPropLoading('DeleteParticipant'))),
                switchMap((group) =>
                    iif(
                        () => {
                            const canRequest = canCancelSchedule(group.OccurredOn);
                            if (canRequest) patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'loading' }, ...setPropLoaded('DeleteParticipant')} );
                            return canRequest;
                        },
                        activityService.deleteParticipant({ OccurredOn: group.OccurredOn, GroupId: group.GroupId, ParticipantId: group.ParticipantId! }).pipe(
                            tapResponse({
                                next: () => {
                                    alertStore.openAlert({
                                        title: 'Reserva cancelada!',
                                        message: 'Sua reserva foi cancelada com sucesso.',
                                        type: EAlertTypes.SUCCESS,
                                        mode: 'alert',
                                    });

                                    const reserveListFiltered = store.ReserveList().groupsDash.filter(g => g.ParticipantId !== group.ParticipantId);
                                    group.ParticipantId = null;
                                    group.ParticipantStatus = null;
                                    group.Participants = group.Participants?.filter(participant => participant.PersonId !== authStore.getAuthData()?.PersonId);
                                    group.NumberVacanciesAvailable += 1;

                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'loaded' } });
                                    patchState(store, { ReserveList: { ...store.ReserveList(), groupsDash: reserveListFiltered } });
                                },
                                error: (error: HttpErrorResponse) => {
                                    errorHandlerService.handleError(error);
                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'error' }, ...setPropError('DeleteParticipant') });
                                }
                            }),
                        ),
                        of(null)
                    )
                )
            )),

            isFinished(group: IGroup): boolean {
                if (group) return DateTime.fromISO(group.EndOccurredOn).diffNow().milliseconds < 0;
                return false;
            },

            clearConfigGroupsDash,

            clearConfigReserveList,

            clearDeleteParticipant: () => {
                patchState(store, setPropInit('DeleteParticipant'));
            },

            clearChangeParticipantStatus: () => {
                patchState(store, setPropInit('ChangeParticipantStatus'));
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('GroupsDash', 'ReserveList', 'ChangeParticipantStatus', 'DeleteParticipant', 'GetActivitySettings'));
                clearConfigGroupsDash();
                clearConfigReserveList();
            }
        };
    }),
    withReqState('GroupsDash', 'ReserveList', 'ChangeParticipantStatus', 'DeleteParticipant', 'GetActivitySettings'),
);