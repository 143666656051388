export const environment = {
  production: true,
  local: false,
};

export const enum Protocol {
  http = 'http://',
  https = 'https://',
}

export const enum EntityPath {
  odata = 'odata.prd.g.actuar.cloud',
  base = 'actuar-api.prd.g.actuar.cloud',
  afig = 'afig-api.prd.g.actuar.cloud',
  paguela = 'paguela-api.prd.g.actuar.cloud',
  chat = 'chat-api.prd.g.actuar.cloud',
  file_group = 'filegroup-api.prd.g.actuar.cloud',
  workout_service = 'workoutservice-api.prd.g.actuar.cloud',
  workout_view = 'workoutviewservice-api.prd.g.actuar.cloud',
  activity_service = 'activityservice-api.prd.g.actuar.cloud',
  user_service = 'userservice-api.prd.g.actuar.cloud',
  shortener = 'link.pague.la',
  post_service = 'postservice-api.prd.g.actuar.cloud',
  account_service = 'accountservice-api.prd.g.actuar.cloud',
  receivable_service = 'receivableservice-api.prd.g.actuar.cloud',
  client_notification = 'clientnotification-api.prd.g.actuar.cloud',
  acesso = 'acesso-api.prd.g.actuar.cloud',
  graduation_service = 'graduationservice-api.prd.g.actuar.cloud',
  client_device_service = 'clientdeviceservice-api.prd.g.actuar.cloud',
}

export const URLS = {
  actuar_web: 'https://app.actuar.com',
};

export const braspag = {
  providerMerchantId: 'c7f999ad-0ea4-4621-9fd6-6b3d475c5d70',
  providerOrgId: '1snn5n9w'
};


export const url = (
  protocol: Protocol,
  entityPath: EntityPath,
  restUrl?: any
) => `${protocol}${entityPath}${restUrl}`;

export const successCodesCheckout: string[] = ['0', '00', '000', '0000'];
