@if (displayTimer()) { 
    <div #modalTimer @counterInOut class="flex flex-col bg-background-primary w-screen h-screen fixed top-0 left-0 z-[100]">
        <div class="w-[30px] absolute ml-[24px] left-0 top-[calc(20px_+_var(--ion-safe-area-top))]">
            <app-icon (click)="hide()" [name]="'back-arrow'" [extension]="'.svg'" class="cursor-pointer" height="30px"
            width="30px"></app-icon>
        </div>
        <div 
        class="w-full h-full flex flex-col items-center justify-center"
        >
        @defer (on viewport(modalTimer)) {
            <app-timers
            class="w-full h-full" 
            [timerId]="timerId()!"
            ></app-timers>       
        }
        </div>
</div>
}