export enum ECardStatus {
  Reserved = 0,
  Canceled = 1,
  Confirmed = 2,
  Executed = 3
}

export enum ETime {
  "minute" = 0,
  "hour" = 1,
}