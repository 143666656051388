<button [disabled]="disabled() || loading()"
  class="cursor-pointer {{ eButtonStyles[buttonStyle()] }} flex justify-center items-center relative rounded-6xl group disabled:opacity-60 h-primary-button-height w-full transition-all duration-300 font-bold font-Satoshi text-label-lg"
  [ngClass]="{'hover:bg-white' : presentationStore.isWeb() && !disabled() && !loading()}">
  <span class="transition-transform absolute duration-500 translate-x-0"
    [ngClass]="{'transform group-hover:-translate-x-2 ease-in-out' : presentationStore.isWeb() && !disabled() && !loading() && iconName()}">
    {{ title() }}
  </span>

  @if(iconName() || loading()) {
  <div
    [ngClass]="{'group-hover:translate-x-2 translate-x-0 transform' : presentationStore.isWeb() && !disabled() && !loading(),
    'opacity-0 group-hover:opacity-100' : buttonStyle() === 'secondary'
    }"
    class="absolute flex justify-center items-center right-[30px] transition-all ease-in-out duration-500 top-0 bottom-0 my-auto">
    @if(loading()) {
    <app-loading color="black" height="1rem" width="1rem"></app-loading>
    }
    @if(iconName() && !loading()) {
    <app-icon [background]="buttonStyle() === 'primary' ? 'black' : '#697E9D'" height="1rem" width="1rem" [name]="iconName()" [extension]="iconExtension()"></app-icon>
    }
  </div>
  }

</button>