import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IPhysicalAssessment, IPhysicalAssessments } from '@app-models/entities/physical-assessments/physical-assessments.interface';

@Injectable({
  providedIn: 'root'
})
export class PhysicalAssessmentsService {
  private http = inject(HttpClient);

  public getPhysicalAssessments$(id: string): Observable<IPhysicalAssessments> {
    return this.http.get<IPhysicalAssessments>(url(Protocol.https, EntityPath.afig, '/Avaliacoes/ObterAvaliacoesResumidaPorPessoaId'), {
      params: {
        pessoaId: id
      },
      withCredentials: true
    });
  }

  public getPhysicalAssessmentById$(id: string): Observable<IPhysicalAssessment> {
    return this.http.get<IPhysicalAssessment>(url(Protocol.https, EntityPath.afig, '/Avaliacoes/ObterPorIdAnon'), {
      params: {
        id
      },
      withCredentials: true
    });
  }

  public getProtocolsBodyComposition$(id: number): Observable<any> {
    return this.http.get<any>(url(Protocol.https, EntityPath.afig, '/Avaliacoes/ObterProtocolosComposicaoCorporalPorId'), {
      params: {
        id
      },
      withCredentials: true
    });
  }

  public getProtocolsVO2$(id: number): Observable<any> {
    return this.http.get<any>(url(Protocol.https, EntityPath.afig, '/Avaliacoes/ObterProtocolosVO2RealizadoPorId'), {
      params: {
        id
      },
      withCredentials: true
    });
  }
}
