import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private alertStore = inject(AlertStore);

    private openAlert = (message: string, type: EAlertTypes, title?: string): void => {
        this.alertStore.openAlert({
            title: title ?? 'Ops! Algo deu errado.',
            type,
            message
        });
    }

    private getErrorMessage = (error: HttpErrorResponse): string | null => {

        if (error?.error?.errors) {
            const errorKeys = Object.keys(error.error.errors);
            return error.error.errors[errorKeys[0]][0];
        }

        if (error?.error?.Validacao?.Erros) {
            return error.error.Validacao.Erros;
        }

        if (error?.error?.ValidationResult?.Errors) {
            return error.error.ValidationResult.Errors.map((element: any) => element.ErrorMessage)[0];
        }

        if (error?.error?.ModelState?.Erros) {
            return error.error.ModelState.Erros;
        }

        if (error?.error?.errors?.DomainValidations) {
            return error.error.errors.DomainValidations;
        }

        if (error?.error?.Erros) {
            return error.error.Erros;
        }

        return null;
    }

    public handleError(error: HttpErrorResponse): void {
        const errorMessages: string | null = this.getErrorMessage(error);
        let type: EAlertTypes = EAlertTypes.WARNING;
        if (error.status >= 500 && error.status < 600) type = EAlertTypes.DANGER;

        if (errorMessages) {
            this.openAlert(errorMessages, type);
            return;
        }

        if (error.status === 400) {
            this.openAlert('Houve um problema ao processar sua solicitação.', type);
            return;
        }

        if (error.status === 404) {
            this.openAlert('O recurso não foi encontrado', type);
            return;
        }

        if (error.status === 401) {
            this.openAlert('Você não tem permissão para acessar este recurso', type);
            return;
        }

        if (error.status === 403) {
            this.openAlert('Entre em contato com a sua academia para mais informações.', type, 'Assinatura Inativa');
            return;
        }

        if (error.status === 500) {
            this.openAlert('Algo não saiu como esperado. Nossa equipe já foi notificada e está investigando.', type);
            return;
        }
    }

}