import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IChangePaswordRequest, IUser, IUserPayload, IUserResponse } from '@app-profile/data-access/entities/profile.interface';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private http = inject(HttpClient);

    public changeSelfUserPassword(payload: IChangePaswordRequest): Observable<boolean> {
        return this.http.post<boolean>(url(Protocol.https, EntityPath.user_service, '/Auth/ChangePassword'), payload, {
            withCredentials: true,
        });
    }

    public getUserData(): Observable<IUser> {
        return this.http.get<IUser>(url(Protocol.https, EntityPath.base, '/Contas/Usuario'), {
            withCredentials: true,
        });
    }

    public updateUserData(payload: IUserPayload): Observable<IUserResponse> {
        return this.http.put<IUserResponse>(url(Protocol.https, EntityPath.base, '/Contas/Usuario'), payload, {
            withCredentials: true,
        });
    }
}
