import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthStore, IAuthAccessToken } from '@auth/data-access';

export const TokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>, next: HttpHandlerFn,
) => {
  const authStore = inject(AuthStore)
  const isLoggedIn = authStore.isLoggedIn;
  let isRefreshing = false;
  const refreshSubject: BehaviorSubject<IAuthAccessToken> = new BehaviorSubject<IAuthAccessToken>(null);

  const observe = authStore.observableAuthData().subscribe((data) => {
    refreshSubject.next(data);
  });

  const addAuthToken = (request: HttpRequest<any>) => {
    if (!request.withCredentials) {
      return request;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${authStore.getAuthData()?.Token}`);

    return request.clone({ withCredentials: false, headers});
  }

  const handle401Error = (
    request: HttpRequest<any>,
    next: HttpHandlerFn,
  ) => {

    if (!isRefreshing) {
      isRefreshing = true;
      refreshSubject.next(null);

      if (isLoggedIn()) {
        authStore.refreshToken();
      }
    }

    return refreshSubject.pipe(
      filter((token) => token !== null),
      switchMap(() => {
        return next(addAuthToken(request)) as Observable<HttpEvent<any>>;
      })
    );
  }

  return next(addAuthToken(request)).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        return handle401Error(request, next);
      } else {
        return throwError(() => error);
      }
    })
  );
}



