@for (alertItem of alertList; track alertItem) {
    <div @slideX id="5D975619-1AEE"
        class="absolute z-50 bottom-3 right-8 transition-all duration-200 ease-[ease] -translate-y-[calc(var(--position)_*_100%)]"
        [style.--position]="alertList.length - 1 - $index">
        <lib-alert-box id="27EDA3B5-DEFE" [alertItem]="alertItem" />
    </div>
}

@for (confirmItem of confirmList; track confirmItem) {
    @if ($index === 0) {
        <div @slideY @opacity id="48CC792B-A303"
            class="z-[52] absolute top-16 left-0 w-full flex flex-col justify-center items-center">
            <lib-alert-box id="E5F9D47E-13A5" [alertItem]="confirmItem" />
        </div>
        <div id="920BEFF6-03A0" @opacity class="backdrop-brightness-50 absolute z-[51] left-0 top-0 w-screen h-screen">
        </div>
    }
}