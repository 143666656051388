import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { IAccountReceivable, ICreateSale, ICreateSaleResquest, IDeleteCreditCard, IIp, IPaguelaSale, IReceivement, ISubscription } from '@app-payments/data-access/entities/payments.interface';
import { map, Observable } from "rxjs";
import { EntityPath, Protocol, url } from "src/environments/environment";
import { IAuthorization, IPaguelaAccount, IToken, ISale } from "@app-payments/data-access/entities/payments.interface";
import * as signalR from '@microsoft/signalr';
@Injectable({
    providedIn: 'root'
}) export class PaymentsService {
    private http = inject(HttpClient);

    public getToken(tokenId: string): Observable<IToken> {
        return this.http.get<IToken>(url(Protocol.https, EntityPath.paguela, `/Token/ObterPorId`), {
            withCredentials: false,
            params: { id: tokenId },
        });
    }

    public getPaguelaAccount(contaId: string): Observable<IPaguelaAccount> {
        return this.http.get<IPaguelaAccount>(url(Protocol.https, EntityPath.paguela, `/Contas/ObterPorId`), {
            withCredentials: false,
            params: { id: contaId },
        });
    }

    public proccessToken(id: string, authorization: IAuthorization): Observable<ISale> {
        return this.http.post<ISale>(url(Protocol.https, EntityPath.paguela, `/Token/Processar`), authorization, {
            params: { id: id },
            withCredentials: false,
        });
    }

    public getAccountIdPaguelaByPersonId(personId: string): Observable<string> {
        return this.http.get<string>(url(Protocol.https, EntityPath.base, '/Pessoas/ObterPagueLaPessoaIdPorPessoaId'), {
            params: {
                pessoaId: personId
            },
            withCredentials: false
        });
    }

    public getPaguelaSituation(accountId: string): Observable<{ Situacao: number }> {
        return this.http.get<{ Situacao: number }>(url(Protocol.https, EntityPath.paguela, '/Contas/ObterSituacao'), {
            params: {
                id: accountId
            },
            withCredentials: false
        });
    }

    public checkGymHasPix(personId: string): Observable<boolean> {
        return this.http.get<boolean>(url(Protocol.https, EntityPath.base, '/paguela/PossuiServicoPixAtivo'), {
            params: {
                pessoaId: personId
            },
            withCredentials: true
        });
    }

    public getBillsToPay(personId: string): Observable<IAccountReceivable[]> {
        return this.http.get<IAccountReceivable[]>(url(Protocol.https, EntityPath.receivable_service, '/AccountReceivable/GetAccountsReceivablesDetailedByPersonId'), {
            params: {
                personId
            },
            withCredentials: true
        });
    }

    public getPaidBills(personId: string): Observable<IReceivement[]> {
        return this.http.get<IReceivement[]>(url(Protocol.https, EntityPath.receivable_service, '/Receivement/GetReceivementByPersonId'), {
            params: {
                personId
            },
            withCredentials: true
        });
    }

    public getSubscriptions(personId: string): Observable<ISubscription[]> {
        return this.http.get<ISubscription[]>(url(Protocol.https, EntityPath.base, '/Assinaturas/ObterAssinaturaPessoaPorPessoaIdTreino'), {
            params: {
                personId
            },
            withCredentials: true
        });
    }

    public getPagueLaSale(id: string): Observable<IPaguelaSale> {
        return this.http.get<IPaguelaSale>(url(Protocol.https, EntityPath.paguela, '/Vendas/ObterPorId'), {
            params: {
                id
            },
            withCredentials: true
        });
    }

    public createSaleCard(data: ICreateSaleResquest): Observable<ICreateSale> {
        return this.http.post<ICreateSale>(url(Protocol.https, EntityPath.receivable_service, '/SalesOperations/CreateCardSale'), data, {
            withCredentials: true
        });
    }

    public createSalePix(data: ICreateSaleResquest): Observable<ICreateSale> {
        return this.http.post<ICreateSale>(url(Protocol.https, EntityPath.receivable_service, '/SalesOperations/CreatePixSale'), data, {
            withCredentials: true
        });
    }

    public deleteCard(cardId: string): Observable<IDeleteCreditCard> {
        return this.http.delete<IDeleteCreditCard>(url(Protocol.https, EntityPath.paguela, '/Cartoes/Remover'), {
            params: {
                id: cardId
            }
        });
    }

    public shortenLink(link: string): Observable<string> {
        return this.http.post<string>(`${Protocol.https}${EntityPath.shortener}`, {
            originalLink: link,
            sourceApi: 'paguela'
        }, {
            responseType: 'text' as 'json'
        });
    }

    public getIp(): Observable<IIp> {
        return this.http.get<IIp>('https://api.ipify.org/?format=json');
    }

    public getConnection(accountId: string): signalR.HubConnection {
        return new signalR.HubConnectionBuilder()
            .withUrl(`${Protocol.https}${EntityPath.client_notification}/NotificationHub?ContaId=${accountId}`,
                { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
            .withAutomaticReconnect([5000, 5000, 5000, 10000])
            .build()
    }

    public getAccountsReceivablesByPersonSubscriptionId(personSubscriptionId: string): Observable<IAccountReceivable[]> {
        return this.http.get<Array<IAccountReceivable & { AccountReceivableId: string }>>(url(Protocol.https, EntityPath.receivable_service, '/AccountReceivable/GetAccountsReceivablesByPersonSubscriptionId'), {
            params: {
                personSubscriptionId
            },
            withCredentials: true
        }).pipe(map((data) => data.map((item) => {
            const { AccountReceivableId, AmountFineCurrent, CurrentInterestAmount, CurrentOpenAmount, OriginalAmount, DueOn, PersonSubscriptionId, PersonSubscriptionRenewalId, SourceDescription, SourceType } = item;
            return {
                AccountsReceivableId: AccountReceivableId,
                AmountFineCurrent,
                CurrentInterestAmount,
                CurrentOpenAmount,
                OriginalAmount,
                DueOn,
                PersonSubscriptionId,
                PersonSubscriptionRenewalId,
                SourceDescription,
                SourceId: PersonSubscriptionId,
                SourceType,
            };
        })));
    }
}