import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageFacade } from '@application/localstorage';

export const LanguageInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const language = inject(LocalStorageFacade).getItem('language');
  let headers = req.headers;

  headers = headers.set('Accept-Language', language ?? 'en-US');
  return next(req.clone({ headers }));
}
