import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {map, pipe, switchMap, tap} from 'rxjs';
import { inject, untracked } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import * as cryptoJs from 'crypto-js';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app-auth/data-access/infra/auth.service';
import { IAccountStatus, IAuthAccessToken, IPersonByUser, IAuthPayload, IClientData, IClientDevice, IDeviceInfo } from '@app-auth/data-access/entities/auth.interface';
import { LocalStorageService } from '@app-services/localStorage.service';
import { Router } from '@angular/router';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
import { Device } from '@capacitor/device';

import {SegmentationStore} from '../../segmentation/segmentation.store';
import { Capacitor } from '@capacitor/core';

interface IAuthState {
    AuthData: IAuthAccessToken | null;
    PeopleByUser: IPersonByUser[] | null;
    ClientData: IClientData | null;
    AccountStatus: IAccountStatus | null;
    DeviceInfo: IDeviceInfo | null;
    DeviceReady: boolean;
};

const initialState: IAuthState = {
    AuthData: null,
    PeopleByUser: null,
    ClientData: null,
    AccountStatus: null,
    DeviceInfo: null,
    DeviceReady: false,
};

export const AuthStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        authService = inject(AuthService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        localStorageService = inject(LocalStorageService),
        router = inject(Router),
        segmentationStore = inject(SegmentationStore)
    ) => {


        const registerFCM = (): void => {
            document.addEventListener('deviceready', () => {
                Device.getInfo().then((DeviceInfo) => {
                patchState(store, { DeviceInfo });
                }).then(() => {
                    Device.getId().then((deviceInfo) => {
                        patchState(store, { 
                            DeviceInfo: { ...store.DeviceInfo(), Uuid: deviceInfo?.identifier },
                        });
                    }).then(() => {
                        patchState(store, {...setPropLoaded('deviceInfo')});

                    });
                });
            });
        }

        const setAuthData = (authData: IAuthAccessToken): void => {
            segmentationStore.setUserId(authData.TenantId);
            localStorageService.setItem('authorization', JSON.stringify(authData));
            registerFCM();
            patchState(store, { AuthData: authData });
        }

        const getAllPersonsByUser = rxMethod<void>(pipe(
            switchMap(() => authService.getAllPersonsByUser().pipe(
                tapResponse({
                    next: (PeopleByUser) => {
                        patchState(store, { PeopleByUser, ...setPropLoaded('AuthData') });
                        if (PeopleByUser.length > 1) {
                            router.navigate(['/common/accounts']);
                            return;
                        }
                        if (PeopleByUser.length === 0 || PeopleByUser.length === 1) {
                           // if (segmentationStore.checkFeature('treino-beta')) {
                           //   router.navigate(['/common/beta']);
                           // }
                            router.navigate(['/home']);
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        patchState(store, { ...setPropError('AuthData', error) });
                    }
                })
            ))
        ))

        const initState = (): void => {
            patchState(store, {
                ...initialState,
                ...setPropInit(
                    'sendEmailForgotPassword',
                    'checkResetPasswordCode',
                    'resetPassword',
                    'checkAccountStatus',
                    'AuthData',
                    'confirmEmailAccountAndCreatePassword',
                    'deviceInfo',
                    'refreshClientDevice'
                ),
            });
        }

        const removeDevice = rxMethod<string>(pipe(
            switchMap((deviceId) => authService.removeClientDevice(deviceId).pipe(
                tapResponse({
                    next: () => {
                        patchState(store, { ...setPropLoaded('removeClientDevice') });
                    },
                    error: (error: HttpErrorResponse) => {
                        errorHandlerService.handleError(error);
                        patchState(store, { ...setPropError('removeClientDevice') });
                    }
                })
            ))
        ))

        const logout = (): void => {
            const deviceId = localStorageService.getItem('DeviceId');

            if (deviceId && store.DeviceReady()) {
                removeDevice(deviceId!);
            }

            initState();
            localStorageService.clearAllData();
            const route = Capacitor.getPlatform() === 'web' ? '/common/web-blocking' : '/common/login';
            router.navigate([route]);
        }

        return {
            logout,

            login: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Code'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((payload) => {
                    payload.Email = payload.Email.toLowerCase();
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));

                    return authService.login({ ...payload, AppSource: 'treino' }).pipe(
                        tapResponse({
                            next: (authData) => {
                                setAuthData(authData);
                                getAllPersonsByUser();
                            },
                            error: (error: HttpErrorResponse) => {
                                if (error.status === 400) {
                                    patchState(store, { ...setPropError('AuthData', error) });
                                    return;
                                }
                                errorHandlerService.handleError(error);
                            }
                        })
                    );
                })
            )),

            choosePersonAccount: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((personId) => authService.choosePersonAccount(personId).pipe(
                    tapResponse({
                        next: (result) => {
                            patchState(store, { ...setPropLoaded('AuthData') });
                            setAuthData(result);
                            router.navigate(['/home']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('AuthData', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            getClient: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('getClient') })),
                switchMap((personId) => authService.getClient(personId).pipe(
                    tapResponse({
                        next: (response) => {
                            patchState(store, { ClientData: response, ...setPropLoaded('getClient') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('getClient', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            refreshClientDevice: rxMethod<IClientDevice>(pipe(
                tap(() => patchState(store, { ...setPropLoading('refreshClientDevice') })),
                switchMap((device) => authService.refreshClientDevice(device).pipe(
                    tapResponse({
                        next: (response) => {
                            if (response) {
                                console.log('Dispositivo atualizado');
                            }
                            localStorageService.setItem('DeviceId', response?.DeviceId!);
                            patchState(store, { DeviceInfo: {...store.DeviceInfo(), DeviceId: response?.DeviceId }, ...setPropLoaded('refreshClientDevice') });
                        },
                        error: (error: HttpErrorResponse) => {
                            console.error('Não foi possível atualizar o dispositivo');
                            patchState(store, { ...setPropLoaded('refreshClientDevice') });
                        }
                    })
                ))
            )),

            checkAccountStatus: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkAccountStatus') })),
                switchMap((email) => authService.checkAccountStatus(email).pipe(
                    tapResponse({
                        next: (accountStatus) => {
                            patchState(store, { AccountStatus: accountStatus, ...setPropLoaded('checkAccountStatus') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkAccountStatus', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            sendEmailForgotPassword: rxMethod<Pick<IAuthPayload, 'Email'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('sendEmailForgotPassword') })),
                switchMap((payload) => {
                    const success = () => {
                        alertStore.openAlert({
                            title: 'Código enviado por e-mail!',
                            message: 'Verifique o código para redefinir sua senha em seu e-mail.',
                            mode: 'alert',
                            type: EAlertTypes.SUCCESS,
                        });
                        patchState(store, { ...setPropLoaded('sendEmailForgotPassword') });

                        const base64 = window.btoa(JSON.stringify(payload));
                        router.navigate(['common/confirm-code', base64]);
                    }
                    return authService.sendEmailResetPassword(payload).pipe(
                        tapResponse({
                            next: success,
                            error: (error: HttpErrorResponse) => {
                                const errorEmail = error.error.errors['Email'];

                                if (errorEmail?.find((error: string) => error === 'Email de confirmação já foi enviado')) {
                                    success();
                                } else {
                                    patchState(store, { ...setPropError('sendEmailForgotPassword', error) });
                                    errorHandlerService.handleError(error);
                                }
                            }
                        })
                    )
                })
            )),

            checkResetPasswordCode: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Password'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkResetPasswordCode') })),
                switchMap((payload) => authService.checkResetPasswordCode(payload).pipe(
                    tapResponse({
                        next: () => {
                            const base64 = window.btoa(JSON.stringify(payload));
                            router.navigate(['common/redefine-password', base64]);
                            patchState(store, { ...setPropLoaded('checkResetPasswordCode') });
                            alertStore.openAlert({
                                title: 'Código confirmado!',
                                message: 'Redefina sua senha.',
                                mode: 'alert',
                                type: EAlertTypes.SUCCESS,
                            });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkResetPasswordCode', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            resetPassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('resetPassword') })),
                switchMap((payload) => {
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));
                    return authService.resetPassword(payload).pipe(
                        tapResponse({
                            next: () => {
                                patchState(store, { ...setPropLoaded('resetPassword') });
                                alertStore.openAlert({
                                    title: 'Senha alterada!',
                                    message: 'Sua senha foi alterada com sucesso.',
                                    mode: 'alert',
                                    type: EAlertTypes.SUCCESS,
                                });
                                router.navigate(['common/login']);
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('resetPassword', error) });
                                errorHandlerService.handleError(error);
                            }
                        })

                    )
                })
            )),

            initState,

            confirmEmailAccountAndCreatePassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('confirmEmailAccountAndCreatePassword') })),
                map((payload) => {
                  payload.Email = payload.Email.toLowerCase();
                  payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));
                  return payload;
                }),
                switchMap((payload) => authService.confirmEmailAccountAndCreatePassword(payload).pipe(
                    tapResponse({
                        next: () => {
                            patchState(store, { ...setPropLoaded('confirmEmailAccountAndCreatePassword') });
                            alertStore.openAlert({
                                title: 'Senha criada!',
                                message: 'Sua senha foi criada com sucesso.',
                                mode: 'alert',
                                type: EAlertTypes.SUCCESS,
                            });
                            router.navigate(['common/login']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('confirmEmailAccountAndCreatePassword', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),


            refreshToken: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('refreshToken') })),
                switchMap((TokenId) => authService.refreshToken(TokenId).pipe(
                    tapResponse({
                        next: (result) => {
                            setAuthData(result);
                            patchState(store, { ...setPropLoaded('refreshToken') });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('refreshToken') });
                        }
                    })
                ))
            ))
        }
    }),
    withReqState('AuthData', 'sendEmailForgotPassword', 'checkResetPasswordCode', 'resetPassword', 'checkAccountStatus', 'confirmEmailAccountAndCreatePassword', 'refreshToken', 'getClient', 'deviceInfo','refreshClientDevice'),
    withMethods((store, localStorageService = inject(LocalStorageService)) => {
        const getAuthData = (): IAuthAccessToken | null => {
            if (!store.AuthData()) {
                const authData = localStorageService.getItem('authorization');
                if (!authData) {
                    untracked(() => store.logout());
                    return null;
                }
                patchState(store, { AuthData: JSON.parse(authData) });
            }

            return store.AuthData();
        }
        return {
            getAuthData,
            isMaster(): boolean {
                const authData = getAuthData();
                return authData?.PersonId === authData?.TenantId;
            },
            isLoggedIn(): boolean {
                const isLoggedIn = !!localStorageService.getItem('authorization');
                if (!isLoggedIn) localStorageService.clearAllData();
                return isLoggedIn;
            }
        }
    }),
    withHooks({
        onInit(store) {
            document.addEventListener('deviceready', () => {
                patchState(store, { DeviceReady: true });
            });
        },
    }),
);
