import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from '@presentation/config';
import { environment } from './environments/environment';
import { PRESENTATION_SHELL_ROUTES } from '@presentation/treino-shell';

bootstrapApplication(
  AppComponent,
  appConfig(environment, PRESENTATION_SHELL_ROUTES)
).catch((err) => console.error(err));
