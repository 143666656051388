import { effect, inject, untracked } from '@angular/core';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from "@ngrx/operators";
import { concatMap, iif, of, pipe, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { ChatService } from '@app-chat/data-access/infra/chat.service';
import { IChat, IMediaPreview, IMessage, IMessageFromConnection, IMessageToSend } from '@app-chat/data-access/entities/chat.interface';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { sortByDateAscendingOrder } from '@shared/utils/sort-by-date.util';
import { DateTime } from 'luxon';
import * as signalR from '@microsoft/signalr';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from '@app-services/localStorage.service';
import { Network } from '@capacitor/network';

interface IChatState {
    ChatInfo: IChat | null;
    HasUnreadMessage: boolean;
    IsOpenChat: boolean;
    Messages: {
        messages: IMessage[];
        isFinished: boolean;
    };
    Connection: signalR.HubConnection | null;
    MessagesToBeSent: IMessage[];
    MediaPreview: IMediaPreview | null;
};

const initialState: IChatState = {
    ChatInfo: null,
    HasUnreadMessage: false,
    IsOpenChat: false,
    Messages: {
        messages: [],
        isFinished: false,
    },
    Connection: null,
    MessagesToBeSent: [],
    MediaPreview: null,
};

export const ChatStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        chatService = inject(ChatService),
        authStore = inject(AuthStore),
        errorHandlerService = inject(ErrorHandlerService),
        localStorageService = inject(LocalStorageService)
    ) => {

        const updateMessages = (newMessages?: IMessage[]) => {
            const localMessages: Record<string, IMessage> = {};
            const messagesToSendById: Record<string, IMessage> = {};
            const unreadedMessage: Record<string, IMessage> = {};

            const currentMessages = store.Messages().messages.length ? store.Messages().messages : getLocalMessages();

            currentMessages.forEach(message => {
                if (!('isMedia' in message)) message.isMedia = Boolean(message.File?.Data || message.File?.Url);
                localMessages[message.MessageId] = message;
                if (!message.ChatId && ((message.isMedia && message.File) || !message.isMedia)) messagesToSendById[message.MessageId] = message;
                if (!message.ReadedBy.find(readedBy => readedBy.ReaderId === authStore.getAuthData()?.PersonId)) unreadedMessage[message.MessageId] = message;
            });

            newMessages?.forEach(message => {
                if (!('isMedia' in message)) message.isMedia = Boolean(message.File?.Data || message.File?.Url);
                localMessages[message.MessageId] = message;
                if (messagesToSendById[message.MessageId] && message.ChatId) delete messagesToSendById[message.MessageId];
                if (!message.ReadedBy.find(readedBy => readedBy.ReaderId === authStore.getAuthData()?.PersonId)) unreadedMessage[message.MessageId] = message;
                else delete unreadedMessage[message.MessageId];
            })

            const unreadedMessageIds = Object.keys(unreadedMessage);
            patchState(store, { HasUnreadMessage: Boolean(unreadedMessageIds.length) });
            if (unreadedMessageIds.length && store.IsOpenChat()) markMessagesReaded(unreadedMessageIds);

            const messagesToSend = sortByDateAscendingOrder(Object.values(messagesToSendById), ['SendAt'])
            if (messagesToSend.length) patchState(store, { MessagesToBeSent: [...store.MessagesToBeSent(), ...messagesToSend] });

            const messages = sortByDateAscendingOrder(Object.values(localMessages), ['SendAt']);

            patchState(store, { Messages: { ...store.Messages(), messages } });

            localStorageService.setItem('LocalMessages', JSON.stringify(messages));
        }

        const getMessages = rxMethod<{ withSkip: boolean }>(pipe(
            switchMap(({ withSkip }) => {
                const params = {
                    chatId: store.ChatInfo()!.ChatId,
                    take: 50,
                    skip: withSkip ? store.Messages().messages.length : 0,
                    lessThanDate: DateTime.now().plus({ days: 1 }).toISODate()
                }

                return iif(
                    () => {
                        const canRequest = !store.Messages().isFinished;
                        if (canRequest) patchState(store, { ...setPropLoading('Messages') });
                        return canRequest;
                    },
                    chatService.getMessages(params).pipe(
                        tapResponse({
                            next: (response) => {
                                updateMessages(response);

                                patchState(store, {
                                    Messages: {
                                        messages: store.Messages().messages,
                                        isFinished: response.length < params.take
                                    },
                                    ...setPropLoaded('Messages')
                                })
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('Messages') });
                                errorHandlerService.handleError(error)
                            }
                        })
                    ),
                    of(null),
                )
            }),
        ));

        const openConnection = () => {
            if (store.Connection() && store.Connection()?.state === signalR.HubConnectionState.Connected) {
                leaveChat();
                enterChat();
                return;
            }

            const connection = chatService.openConnection(authStore.getAuthData()!);
            patchState(store, { Connection: connection });

            connection.start().then(() => enterChat()).catch((error) => {
                if (error && error.statusCode === 401) {
                    authStore.refreshToken(authStore.getAuthData()?.TokenId!);
                }
            });

            connection.onreconnected(() => enterChat());
        };

        const enterChat = () => {
            if (store.Connection()?.state !== signalR.HubConnectionState.Connected || !store.ChatInfo()?.ChatId) return;

            store.Connection()?.invoke('Subscribe', store.ChatInfo()!.ChatId).then(() => {
                store.Connection()?.on('ReciveMessage', (data: IMessageFromConnection) => reciveMessage(data));
                store.Connection()?.on('MessageRead', (data: IMessageFromConnection[]) => messageReaded(data));
            }).catch((e) => {
                console.error(e);
            });

            updateMessages();
        }

        const leaveChat = () => {
            if (store.Connection() && store.Connection()?.state !== signalR.HubConnectionState.Connected) { return; }
            if (store.ChatInfo()?.ChatId) {
                store.Connection()?.invoke('Unsubscribe', store.ChatInfo()?.ChatId);
            }
        }

        const messageReaded = (data: IMessageFromConnection[]) => {
            const messages = data.map(messageAdapter);
            updateMessages(messages);
        }

        const reciveMessage = (data: IMessageFromConnection) => {
            const message = messageAdapter(data);
            updateMessages([message]);
        }

        const messageAdapter = (data: IMessageFromConnection): IMessage => {
            const message: IMessage = {
                MessageId: data.messageId,
                ChatId: data.chatId,
                Text: data.text,
                Sender: data.sender,
                SenderId: data.senderId,
                ReciverId: data.reciverId,
                SendAt: data.sendAt,
                File: null,
                ReadedBy: data.readedBy.map(element => {
                    return {
                        ReadOn: element.readOn,
                        ReaderId: element.readerId
                    }
                }),
                isMedia: Boolean(data?.file?.data || data?.file?.url)
            };

            if (message.isMedia) message.File = {
                Url: data?.file?.url,
                FileName: data?.file?.fileName,
                Data: data?.file?.data
            };

            return message;
        }

        const markMessagesReaded = (messageIds: string[]) => {
            if (!store.Connection() || store.Connection()?.state !== signalR.HubConnectionState.Connected) return;
            store.Connection()!.invoke('MessageRead', messageIds);
        }

        const closeMediaPreview = () => {
            patchState(store, { MediaPreview: null });
        }

        const getLocalMessages = (): IMessage[] => {
            const localMessagesString = localStorageService.getItem('LocalMessages');
            return localMessagesString ? JSON.parse(localMessagesString) : [];
        }

        return {
            openConnection,
            getMessages,
            closeMediaPreview,

            startChat: rxMethod<void>(pipe(
                switchMap(() =>
                    chatService.getChatInfo().pipe(
                        tapResponse({
                            next: (value) => {
                                patchState(store, { ChatInfo: value, ...setPropLoaded('ChatInfo') });
                                openConnection();
                                if (value.LastMessage && value.LastMessage.ReciverId === authStore.getAuthData()?.PersonId &&
                                    !value.LastMessage.ReadedBy.find(readedBy => readedBy.ReaderId === authStore.getAuthData()?.PersonId)) {
                                    patchState(store, { HasUnreadMessage: true });
                                }
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('ChatInfo') });
                                errorHandlerService.handleError(error);
                            }
                        })
                    )
                )
            )),

            sendMessage: (message: IMessageToSend) => {
                const messageFormated: IMessage = {
                    ChatId: '',
                    File: null,
                    MessageId: uuid(),
                    ReadedBy: [],
                    ReciverId: '',
                    SendAt: DateTime.now().toISO(),
                    Sender: '',
                    SenderId: authStore.getAuthData()?.PersonId!,
                    Text: message?.Text,
                    isMedia: Boolean(message?.File?.Data)
                }

                const localMessages = getLocalMessages();
                localStorageService.setItem('LocalMessages', JSON.stringify([...localMessages, messageFormated]));

                if (messageFormated.isMedia) messageFormated.File = {
                    Data: message?.File?.Data,
                    FileName: message?.File?.FileName,
                    Url: message?.File?.Url
                };

                patchState(store, {
                    MessagesToBeSent: [...store.MessagesToBeSent(), messageFormated],
                    Messages: { ...store.Messages(), messages: [...localMessages, messageFormated] }
                });

                closeMediaPreview();
            },

            uploadMedia: rxMethod<IMessage>(pipe(
                concatMap(({ File, Text, MessageId }) =>
                    chatService.uploadMedia({
                        MessageId,
                        ChatId: store.ChatInfo()?.ChatId!,
                        Text: Text || '1 arquivo recebido.',
                        File: {
                            Data: File?.Data?.replace(/^data:[\w/]+;base64,/, ''),
                            FileName: File?.FileName
                        }
                    }).pipe(
                        tapResponse({
                            next: () => { },
                            error: (error: HttpErrorResponse) => errorHandlerService.handleError(error)
                        })
                    )
                )
            )),

            openMediaPreview: ({ File, Text }: Pick<IMessage, 'File'> & { Text?: string | null }) => {
                if (!File || (!File.Data && !File.Url)) return;
                let Type = '';

                if (File.Url) {
                    const extension = File?.Url.split('.').pop()!;
                    Type = ['jpg', 'jpeg', 'png', 'gif', 'jpe'].includes(extension) ? `image/${extension}` : ['mp4'].includes(extension) ? `video/${extension}` : '';
                }

                if (File.Data) Type = File.Data.match(/data:(.*?);/)?.[1] ?? '';

                patchState(store, { MediaPreview: { Src: (File.Url || File.Data)!, Text, Type } });
            },

            toggleIsOpenChat: (value: boolean) => {
                patchState(store, { IsOpenChat: value });
            },

            getLocalMessages: () => {
                let localMessages = getLocalMessages();
                if (localMessages.length > 100) localMessages = localMessages.slice(-100);
                localStorageService.setItem('LocalMessages', JSON.stringify(localMessages));

                patchState(store, { Messages: { messages: localMessages, isFinished: false } });
                getMessages({ withSkip: false });
            },

            clearChat: () => {
                leaveChat();
                patchState(store, { ...initialState });
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('ChatInfo', 'Messages'));
            }
        };
    }),

    withReqState('ChatInfo', 'Messages'),
    withHooks({
        onInit: (store, authStore = inject(AuthStore)) => {
            Network.addListener('networkStatusChange', status => {
                if (status.connected && store.Connection()?.state === signalR.HubConnectionState.Disconnected) untracked(() => store.openConnection());
            });
            effect(() => {
                if (authStore.refreshTokenLoaded()) {
                    untracked(() => store.openConnection());
                }
            });
            effect(() => {
                if (store.MessagesToBeSent().length && store.Connection() && store.Connection()?.state === signalR.HubConnectionState.Connected && store.ChatInfo()) {
                    untracked(() => {
                        const messagesToBeSent = store.MessagesToBeSent();
                        while (messagesToBeSent.length) {
                            const message = messagesToBeSent.shift();

                            if (message?.isMedia) store.uploadMedia(message);
                            else store.Connection()!.invoke('SendMessageWithMessageId', store.ChatInfo()?.ChatId, message?.Text, message?.MessageId);

                            patchState(store, { MessagesToBeSent: messagesToBeSent });
                        }

                    });
                }
            });
            effect(() => {
                if (!authStore.AuthData()) {
                    untracked(() => store.clearChat());
                }
            })
        },
    })
);