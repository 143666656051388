import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from "@ngrx/operators";
import { pipe, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AboutGymService } from '@app-about-gym/data-access/infra/about-gym.service';
import { IAboutGym, IOperationPeriods } from '@app-about-gym/data-access/entities/about-gym.interface';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
interface IAboutGymState {
    AboutGym: IAboutGym | null;
    OperationPeriod: IOperationPeriods[] | null;
};

const initialState: IAboutGymState = {
    AboutGym: null,
    OperationPeriod: null,
};

export const AboutGymStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        aboutGymService = inject(AboutGymService),
    ) => {
        return {
 
            getAboutGym: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AboutGym') })),
                switchMap(() => {
                    return aboutGymService.getAboutGym().pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { AboutGym: response, ...setPropLoaded('AboutGym') });
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('AboutGym') });
                            }
                        })
                    )
                })
            )),

             
            getOperationPeriod: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('OperationPeriod') })),
                switchMap(() => {
                    return aboutGymService.getOperationPeriod().pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { OperationPeriod: response, ...setPropLoaded('OperationPeriod') });
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('OperationPeriod') });
                            }
                        })
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState, setPropInit('AboutGym', 'OperationPeriod'));
            }
        };
    }),
    withReqState('AboutGym', 'OperationPeriod'),

);