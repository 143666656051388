import { Routes } from '@angular/router';
import { createFeature, provideState } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { DomainGuard } from '@guard';
import { PresentationUiComponent } from '@presentation/ui';
import { AuthTokenReceiverComponent } from '@auth/token-receiver-feature';

export const PRESENTATION_SHELL_ROUTES: Routes = [
  { path: 'transmission', loadComponent: () => AuthTokenReceiverComponent },
  { path: '',
    component: PresentationUiComponent,
    providers: [
      provideState(createFeature({name: 'router', reducer: routerReducer})),
    ],
    children: [
      {
        path: '', loadChildren: async () =>
          (await import('@presentation/core/treino-shell')).CORE_SHELL_ROUTES,
        canActivate: [DomainGuard],
      },
    ]
  },
  { path: '**', redirectTo: 'common' }
];
