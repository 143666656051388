import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from "@ngrx/operators";
import { pipe, switchMap, tap } from 'rxjs';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DateTime } from 'luxon';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { FrequencyService } from '@app-frequency/data-access/infra/frequency.service';
import { IInfoTimes } from '@app-frequency/data-access/entities/frequency.interface';

interface IFrequencyState {
    Frequency: Record<string, IInfoTimes[]>;
    isFrequentInMonth: boolean;
};

const initialState: IFrequencyState = {
    Frequency: {},
    isFrequentInMonth: false,
};

export const FrequencyStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        frequencyService = inject(FrequencyService),
        authStore = inject(AuthStore),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
    ) => {

        return {
            getFrequency: rxMethod<DateTime>(pipe(
                tap(() => patchState(store, { Frequency: {}, ...setPropLoading('Frequency') })),
                switchMap((date) => {
                    return frequencyService.getFrequency(date, String(authStore.AuthData()?.PersonId)).pipe(
                        tapResponse(
                            (response) => {
                                response.value.forEach(element => {
                                    const infoTimes = {
                                        enterDate: DateTime.fromISO(element.DataEntrada).toUTC(),
                                        leaveDate: DateTime.fromISO(element.DataSaida).toUTC()
                                    };
                                    const key = infoTimes.enterDate.toISODate();
                                    const value = store.Frequency()[key!] ?? [];
                                    value.push(infoTimes);
                                    patchState(store, {
                                        Frequency: { ...store.Frequency(), [key!]: value }
                                    });
                                });

                                patchState(store, {
                                    isFrequentInMonth: Boolean(response.value.length),
                                    ...setPropLoaded('Frequency')
                                });
                            },
                            (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { Frequency: {}, ...setPropError('Frequency') });
                            }
                        )
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState, setPropInit('Frequency'));
            }

        };
    }),
    withReqState('Frequency'),
);