import { DateTime } from "luxon";

export const sortByDateAscendingOrder = (array: any[], attrs: string[],) => {
    array.sort((a, b) => {
        let valueA: any = a
        let valueB: any = b
        attrs.forEach(attr => {
            valueA = valueA[attr]
            valueB = valueB[attr]
        })
        if (DateTime.fromISO(valueA) > DateTime.fromISO(valueB)) {
            return 1;
        } else if (DateTime.fromISO(valueA) < DateTime.fromISO(valueB)) {
            return -1;
        } else {
            return 0;
        }
    });
    return array;
}

export const sortByDateDescendingOrder = (array: any[], attrs: string[]) => {
    array.sort((a, b) => {
        let valueA: any = a
        let valueB: any = b
        attrs.forEach(attr => {
            valueA = valueA[attr]
            valueB = valueB[attr]
        })
        if (DateTime.fromISO(valueA) < DateTime.fromISO(valueB)) {
            return 1;
        } else if (DateTime.fromISO(valueA) > DateTime.fromISO(valueB)) {
            return -1;
        } else {
            return 0;
        }
    });
    return array;
} 