import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalStorageFacade } from '@application/localstorage';
import { RouterStore } from '@route/data-access';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { AuthStore } from '@auth/data-access';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'lib-auth-token-receiver',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './auth-feature-src-lib-token-receiver.component.html',
  styleUrl: './auth-feature-src-lib-token-receiver.component.css',
})
export class AuthTokenReceiverComponent {
  private localStorage = inject(LocalStorageFacade);
  private routerStore = inject(RouterStore);
  private authStore = inject(AuthStore);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  constructor() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(( params ) => {
      if (params['token']) {
        const data =  window.atob(params['token'])
        this.authStore.setAuthData(JSON.parse(data));
        this.localStorage.setItem('authorization', data);

        if (!Capacitor.isNativePlatform()) {
          this.router.navigate(['/']).then();
        }

        if (Capacitor.isNativePlatform()) {
          location.href = 'https://localhost/';
        }
      }
    })
  }
}
