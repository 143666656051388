import { CommonModule } from '@angular/common';
import { Component, ElementRef, Signal, effect, inject, input, signal, untracked, viewChild } from '@angular/core';
import { IAlertItem } from '@app-models/entities/alerts/alerts.interface';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  imports: [CommonModule],
  standalone: true
})
export class AlertComponent {
  public readonly alertStore = inject(AlertStore);
  public readonly bar: Signal<ElementRef<HTMLSpanElement> | undefined> = viewChild('bar');
  public readonly alertBox: Signal<ElementRef<HTMLDivElement> | undefined> = viewChild('alertBox');
  public readonly alert = input<Partial<IAlertItem>>();
  public readonly height = signal<number>(0);
  public readonly isRemoving = signal<boolean>(false);

  private observeAlert = effect(() => {
    const barComponent = this.bar()?.nativeElement;
    const alertBoxComponent = this.alertBox()?.nativeElement;
    untracked(() => {
      if (barComponent && alertBoxComponent) {
        this.height.set(alertBoxComponent.getBoundingClientRect().height);
        this.addRemovalByDragging();
        this.addAlert();
      }
    })

  });

  private addAlert(): void {
    this.alertBox()!.nativeElement.animate([
      { height: '0px' },
      { height: `${this.height()}px` },
    ], { duration: 200, easing: 'ease', fill: 'forwards' }).onfinish = () => {
      this.addRemovalByTime();
    }
  }

  private addRemovalByTime(): void {
    this.bar()!.nativeElement.animate([
      { width: '100%' },
      { width: '0px' },
    ], { duration: 5000, easing: 'ease-in-out', fill: 'forwards' }).onfinish = () => {
      if (!this.isRemoving()) this.removeAlert();
    }
  }

  private addRemovalByDragging(): void {
    let clientY = 0;
    let diff = 0;
    this.alertBox()!.nativeElement.ontouchstart = (e: TouchEvent) => {
      clientY = e.touches[0].clientY;
    }
    this.alertBox()!.nativeElement.ontouchmove = (e: TouchEvent) => {
      diff = e.touches[0].clientY - clientY;
    }
    this.alertBox()!.nativeElement.ontouchend = (e: TouchEvent) => {
      if (diff > 0 && !this.isRemoving()) this.removeAlert();
    }
  }

  private removeAlert(): void {
    this.isRemoving.set(true);
    this.alertBox()!.nativeElement.animate([
      { height: `${this.height()}px` },
      { height: '0px' },
    ], { duration: 200, easing: 'ease', fill: 'forwards' }).onfinish = () => {
      this.alertStore.closeAlert(String(this.alert()?.id));
    }
  }
}
