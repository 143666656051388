@if (paymentsStore.Voucher(); as voucher) {
<section @backdropAnimation
  class="flex items-center justify-center h-full fixed top-0 right-0 left-0 z-40 opacity-0 backdrop-blur-sm transition-all ease will-change-auto duration-500 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-track-rounded-md scrollbar-thumb-[#283850] scrollbar-track-[#090f18]"
  [ngClass]="voucher ? 'visible backdrop-brightness-50 opacity-100': 'invisible opacity-0'">
  <div class="flex flex-col max-w-[21.18rem] w-full h-full mx-[18px]">
    <div
      class="m-auto font-Satoshi bg-[url('assets/images/voucher.svg')] bg-auto bg-no-repeat bg-center rounded-[20px] relative w-full p-[18px] pt-0 pb-[32px] h-fit flex flex-col">
      <div class="flex flex-col justify-center items-center w-full">
        <img class="w-[7.75rem]" src="assets/images/success.gif" alt="success">
        <h1 class="text-center font-bold text-title-md leading-[30.8px] text-white">Muito Obrigado!</h1>
        <h3 class="text-center font-medium mt-1 text-content-md leading-[22.4px] text-content-secondary mb-[18px]">Sua transação foi finalizada com
          sucesso.</h3>
      </div>
      <div class="flex flex-col w-full pb-6 text-white border-b-[#283850] border-b-[1px] border-solid gap-2">
        @if (voucher?.date; as date) {
        <div class="flex items-center justify-between w-full">
          <h3 class="font-medium text-content-secondary text-label-lg leading-[25.6px]">Data</h3>
          <span class="font-bold text-content-lg leading-[25.6pxpx]">{{ date }}</span>
        </div>
        }
        @if (voucher?.time; as time) {
        <div class="flex items-center justify-between w-full">
          <h3 class="font-medium text-content-secondary text-label-lg leading-[25.6px]">Horário</h3>
          <span class="font-bold text-content-lg leading-[25.6pxpx]">{{ time }}</span>
        </div>
        }
        @if (aboutGymStore.AboutGym()?.DisplayCompanyName; as to) {
        <div class="flex items-center justify-between w-full">
          <h3 class="font-medium text-content-secondary text-label-lg leading-[25.6px]">Para</h3>
          <span class="font-bold text-content-lg leading-[25.6pxpx]"> {{ to }}</span>
        </div>
        }
        @if (voucher?.proof; as proof) {
        <div class="flex items-center justify-between w-full">
          <h3 class="font-medium text-content-secondary text-label-lg leading-[25.6px]">Comprovante</h3>
          <span class="font-bold text-content-lg leading-[25.6pxpx]"> {{ proof }}</span>
        </div>
        }
      </div>
      @if (voucher?.value; as value) {
      <div class="mt-6 w-full flex justify-between ">
        <h2 class="font-medium text-content-xl">Total</h2>
        <span class="font-bold text-content-xl text-green-treino"> {{ value | currency }}</span>
      </div>
      }
      @if (voucher?.brandCardName; as brandCardName) {
      <div class="w-full">
        <div
          class="mt-7 py-4 px-[1.375rem] border-solid border-[#283850] w-full border-[1px] rounded-xl ">
          <div class="w-full h-full flex items-center gap-[1.375rem]">
            <app-brandcard [brandCardName]="brandCardName" [iconType]="'regular'" />
            <div class="text-left">
              <h2 class="text-content-xl text-white text-bold">Cartão de Crédito</h2>
              <h3 class="font-medium mt-1 text-content-md text-content-secondary">{{ brandCardName }} **{{
                voucher?.cardNumber?.substr(-2) }}</h3>
            </div>
          </div>
        </div>
      </div>
      }

      <div class="my-8 relative  mx-auto w-[98%] h-[2px] border-t-2 border-transparent [border-image:repeating-linear-gradient(90deg,#697E9D_0,#697E9D_5px,transparent_5px,transparent_10px)_20]"></div>

      <div class="flex justify-between items-center gap-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="152" height="53" viewBox="0 0 152 53" fill="none">
          <path
            d="M0 52.4343V0.434265H4.85156V52.4343H0ZM7.2388 52.3973V0.434265H9.70312V52.3973H7.2388ZM14.5547 52.3973V0.434265H16.9419V52.3973H14.5547ZM26.6451 52.3973V0.434265H29.0323V52.3973H26.6451ZM36.3482 52.3973V0.434265H41.1227V52.3973H36.3482ZM48.4385 52.3973V0.434265H50.8258V52.3973H48.4385ZM53.2901 52.3973V0.434265H55.6773V52.3973H53.2901ZM58.1417 52.3973V0.434265H60.5289V52.3973H58.1417ZM67.768 52.3973V0.434265H72.6195V52.3973H67.768ZM79.8583 52.3973V0.434265H84.7099V52.3973H79.8583ZM89.5612 52.3973V0.434265H94.413V52.3973H89.5612ZM99.2646 52.3973V0.434265H104.116V52.3973H99.2646ZM106.503 52.3973V0.434265H111.355V52.3973H106.503ZM118.671 52.3973V0.434265H125.91V52.3973H118.671ZM128.297 52.3973V0.434265H130.761V52.3973H128.297ZM133.148 52.4343V0.434265H138V52.4343H133.148Z"
            fill="white" />
          <path d="M142.297 0.434265V52.3973H144.761V0.434265H142.297Z" fill="white" />
          <path d="M147.148 0.434265V52.4343H152V0.434265H147.148Z" fill="white" />
        </svg>
        <div
          class="border-solid border-green-treino border-[1.8px] rounded-[14.529px] text-green-treino text-title-md font-bold px-[1.313rem] py-3.5 text-center">
          PAGO
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full mb-auto">
      <app-button (click)="finish()" title="Voltar para o
      Início"></app-button>
    </div>
  </div>
</section>
}
