import { effect, inject, signal, untracked } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { IAuthAccessToken } from '@app-auth/data-access/entities/auth.interface';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { ProfileStore } from '@app-profile/data-access/profile.store';
import  { LogoutService } from '@app-logout/data-access/infra/logout.service';
export class TokenInterceptor implements HttpInterceptor {
  public readonly logoutService = inject(LogoutService);
  private authStore = inject(AuthStore);
  private profileStore = inject(ProfileStore);
  private refreshSubject = new BehaviorSubject<IAuthAccessToken | null>(null);
  private reTryRefreshToken = 0;
  private isRefreshingToken = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request)).pipe(
      catchError((error, retryRequest) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, retryRequest);
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.handle400Error(error);
        }
        if (error instanceof HttpErrorResponse && error.status === 403) {
          this.profileStore.clearStore();
          this.authStore.logout();
        }
        return throwError(() => error);
      })
    );
  }

  private addAuthToken(request: HttpRequest<any>, token?: IAuthAccessToken | null) {
    const language = signal('pt-BR')();
    let headers = new HttpHeaders().set('Accept-Language', language);

    if (!request.withCredentials) {
      return request.clone({
        headers,
      });
    }

    const _token = token ?? this.authStore.AuthData();
    if (_token?.Token) {
      headers = headers.set('Authorization', `Bearer ${_token?.Token}`);
      return request.clone({
        withCredentials: false,
        headers,
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, retryRequest: Observable<any>) {
    if (this.isRefreshingToken && this.reTryRefreshToken > 5) {
      this.profileStore.clearStore();
      this.authStore.logout();
    }

    this.reTryRefreshToken++;
    if (!this.authStore.refreshTokenLoading()) {
      this.isRefreshingToken = true;
      this.refreshSubject.next(null);

      if (this.authStore.isLoggedIn()) {
        this.authStore.refreshToken(this.authStore.AuthData()?.TokenId!);
      }
    }

    return this.refreshSubject.pipe(
      filter((token) => token !== null),
      switchMap((token) => {
        this.reTryRefreshToken = 0;
        this.isRefreshingToken = false
        return next.handle(this.addAuthToken(request, token));
      })
    );
  }

  private handle400Error(error: HttpErrorResponse) {
    const errorMessage = error.error ? error.error.error : '';

    if (error && error.status === 400 && (errorMessage === 'invalid_grant' || errorMessage === 'invalid_clientId')) {
      this.profileStore.clearStore();
      return this.authStore.logout();
    }

    if (error?.status === 400 && error?.error?.errors?.DomainValidations[0] === 'Não foi encontrado o token.') {
      this.profileStore.clearStore();
      return this.authStore.logout();
    }

    if (error.error.ValidationResult.Errors[0].ErrorMessage === 'Localize.UserService.Msg.InvalidToken') {
      this.profileStore.clearStore();
      return this.authStore.logout();
    }
  }

  private observeRefreshToken = effect(() => {
    if(this.authStore.refreshTokenLoaded()) {
      untracked(() => this.refreshSubject.next(this.authStore.AuthData()));
    }
    if(this.authStore.refreshTokenError()) {
      this.refreshSubject.next(null);
    }
  });
}
