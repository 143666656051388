import { Component, Input } from "@angular/core";
import { fadeSwitch } from "src/shared/animations/animations";

@Component({
    selector: 'app-brandcard',
    templateUrl: './brandcard.component.html',
    styleUrls: ['./brandcard.component.scss'],
    standalone: true,
    animations: [fadeSwitch],
}) export class BrandCardComponent {
    @Input() brandCardName: string | null | undefined;
    @Input() iconType: string | null | undefined;
}