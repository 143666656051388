import { DestroyRef, Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IPhysicalAssessment, IPhysicalAssessments, IRating, IRatingResult, ICapacityRating } from "@app-models/entities/physical-assessments/physical-assessments.interface";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { PhysicalAssessmentsService } from "src/app/services/physical-assessments.service";
import { BODY_COMPOSITION_CONFIG, FEMININE_CONFIG, MASCULINE_CONFIG } from "./physical-assessments.config";
import { IBodyComposition } from "@app-models/entities/physical-assessments/body-composition.interface";
import { findValueRange } from "@shared/utils/find-value-range.util";
import { AuthStore } from "@app-auth/data-access/auth.store";

export type RequestState = Record<string, string>;
@Injectable({
    providedIn: 'root',
})
export class PhysicalAssessmentsFacade {
    private initialState: Record<string, string> = {
        getPhysicalAssessments: 'PhysicalAssessmentsInit',
        getPhysicalAssessmentById: 'PhysicalAssessmentByIdInit',
        
    };
    public initialValue: Record<string, any> = {
        getPhysicalAssessments: null, 
        getPhysicalAssessmentById: null,

    };

    public stateSubject$ = new BehaviorSubject<RequestState>(this.initialState);
    public physicalAssessmentsSubject$ = new BehaviorSubject<Record<string, any>>(this.initialValue);
    private physicalAssessmentsService = inject(PhysicalAssessmentsService);

    public bodyComposition$ = new BehaviorSubject<IBodyComposition | null>(null);
    private destroyRef = inject(DestroyRef);
    private authStore = inject(AuthStore);

    public getPhysicalAssessments$(): void {
        this.setState('getPhysicalAssessments', 'PhysicalAssessmentsLoading');
        this.physicalAssessmentsService.getPhysicalAssessments$(this.authStore.AuthData()?.PersonId as string).pipe(takeUntilDestroyed(this.destroyRef), map((physicalAssessments) => {
            this.setState('getPhysicalAssessments', 'PhysicalAssessmentsLoaded');
            physicalAssessments?.Avaliacoes?.sort((a, b) => {
                return Number(new Date(b.DataAvaliacao)) - Number(new Date(a.DataAvaliacao));
            });

            this.setData('getPhysicalAssessments', physicalAssessments);
        })).subscribe();
    }

    public getPhysicalAssessmentById(id: string): void {
        this.setState('getPhysicalAssessmentById', 'PhysicalAssessmentByIdLoading');
        this.physicalAssessmentsService.getPhysicalAssessmentById$(id).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((physicalAssessment) => {
                this.setState('getPhysicalAssessmentById', 'PhysicalAssessmentByIdLoaded');
                this.getAnamnesis(physicalAssessment);
                this.getIdealBodyMass(physicalAssessment);
                this.getFatPercentage(physicalAssessment);
                this.setData('getPhysicalAssessmentById', physicalAssessment);
            });
    }


    public getBodyComposition(physicalAssessment: IPhysicalAssessment, refreshing: boolean = false): void {
        if (this.bodyComposition$.getValue() && this.bodyComposition$.getValue()?.physicalAssessmentId === physicalAssessment.AvaliacaoId && !refreshing) return;

        const bodyComposition: IBodyComposition = { ...BODY_COMPOSITION_CONFIG };
        bodyComposition.physicalAssessmentId = physicalAssessment.AvaliacaoId
        let taxaMetaBasal = 0;

        if ((physicalAssessment.Massa > 0) && (physicalAssessment.Estatura > 0)) {
            bodyComposition.IMC = (physicalAssessment.Massa / Math.pow((physicalAssessment.Estatura / 100), 2)).toFixed(2);
            if (physicalAssessment.PessoaSexo === 'M') {
                taxaMetaBasal = 66 + (13.7 * physicalAssessment.Massa) + (5 * physicalAssessment.Estatura) - (6.8 * physicalAssessment.PessoaIdade);
                bodyComposition.TaxaMetaBasal = taxaMetaBasal.toFixed(2);
            } else if (physicalAssessment.PessoaSexo === 'F') {
                taxaMetaBasal = 655 + (9.6 * physicalAssessment.Massa) + (1.7 * physicalAssessment.Estatura) - (4.7 * physicalAssessment.PessoaIdade);
                bodyComposition.TaxaMetaBasal = taxaMetaBasal.toFixed(2);
            }
            if (physicalAssessment) {
                if (physicalAssessment.ValorNivelAtividade) {
                    bodyComposition.GastoEnergeticoTotal = Number((taxaMetaBasal * physicalAssessment.ValorNivelAtividade).toFixed(2));
                }
            }
        } else {
            bodyComposition.IMC = '';
        }

        bodyComposition.GC = physicalAssessment.GC;
        bodyComposition.Estatura = physicalAssessment.Estatura;
        bodyComposition.Massa = physicalAssessment.Massa;
        bodyComposition.TaxaMetabolicaBasal = physicalAssessment.TaxaMetabolicaBasal;

        if (physicalAssessment.ProtocoloComposicaoCorporalId) this.getProtocolsBodyComposition(bodyComposition, physicalAssessment.ProtocoloComposicaoCorporalId);

        this.bodyComposition$.next(bodyComposition);
    }

    private getProtocolsBodyComposition(bodyComposition: IBodyComposition, id: number): void {
        this.physicalAssessmentsService.getProtocolsBodyComposition$(id).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((protocols) => {
                const splitProtocol = protocols.Descricao.split(',');
                const firstPart = splitProtocol.shift();
                bodyComposition.ProtocoloComposicaoCorporal = firstPart.split('.')[1];
                bodyComposition.DescResumidaProtocoloComposicao = splitProtocol.join(',');
                this.bodyComposition$.next({ ...bodyComposition });
            });
    }

    public getProtocolsVO2(physicalAssessment: IPhysicalAssessment, id: number): void {
        this.physicalAssessmentsService.getProtocolsVO2$(id).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((protocols) => {
                this.getVO2(physicalAssessment, protocols);
                this.setData('getPhysicalAssessmentById', physicalAssessment);
            });
    }

    private getAnamnesis(physicalAssessment: IPhysicalAssessment): void {
        const goals = []
        if (physicalAssessment.ObjetivoCompeticao) {
            goals.push('Competição');
        }
        if (physicalAssessment.ObjetivoCondicionamento) {
            goals.push('Condicionamento');
        }
        if (physicalAssessment.ObjetivoDefinicao) {
            goals.push('Definição');
        }
        if (physicalAssessment.ObjetivoEmagrecer) {
            goals.push('Emagrecer');
        }
        if (physicalAssessment.ObjetivoHipertrofia) {
            goals.push('Hipertrofia');
        }
        if (physicalAssessment.ObjetivoLazer) {
            goals.push('Lazer');
        }
        if (physicalAssessment.ObjetivoSaude) {
            goals.push('Saúde');
        }
        if (physicalAssessment.ObjetivoTerapeutico) {
            goals.push('Terapêutico');
        }
        if (physicalAssessment.ObjetivoOutroTexto) {
            goals.push(physicalAssessment.ObjetivoOutroTexto);
        }

        physicalAssessment.Objetivos = goals.join(', ');

        physicalAssessment.DoresLesoes = [];

        for (const key in physicalAssessment.AvaliacaoDoresLesoes) {
            if (physicalAssessment.AvaliacaoDoresLesoes[key]) {
                physicalAssessment.DoresLesoes?.push(physicalAssessment.AvaliacaoDoresLesoes[key].Descricao);
            }
        }

        if (physicalAssessment.PessoaSexo === 'M') {
            switch (physicalAssessment.NivelAtividadeFisica) {
                case 0:
                    physicalAssessment.NivelAtividadeDescricao = 'Sedentário';
                    physicalAssessment.ValorNivelAtividade = 1.3;
                    break;
                case 1:
                    physicalAssessment.NivelAtividadeDescricao = 'Leve';
                    physicalAssessment.ValorNivelAtividade = 1.6;
                    break;
                case 2:
                    physicalAssessment.NivelAtividadeDescricao = 'Moderado';
                    physicalAssessment.ValorNivelAtividade = 1.7;
                    break;
                case 3:
                    physicalAssessment.NivelAtividadeDescricao = 'Intenso';
                    physicalAssessment.ValorNivelAtividade = 2.1;
                    break;
                case 4:
                    physicalAssessment.NivelAtividadeDescricao = 'Extremo';
                    physicalAssessment.ValorNivelAtividade = 2.4;
                    break;
            }
        } else if (physicalAssessment.PessoaSexo === 'F') {
            switch (physicalAssessment.NivelAtividadeFisica) {
                case 0:
                    physicalAssessment.NivelAtividadeDescricao = 'Sedentária';
                    physicalAssessment.ValorNivelAtividade = 1.3;
                    break;
                case 1:
                    physicalAssessment.NivelAtividadeDescricao = 'Leve';
                    physicalAssessment.ValorNivelAtividade = 1.5;
                    break;
                case 2:
                    physicalAssessment.NivelAtividadeDescricao = 'Moderado';
                    physicalAssessment.ValorNivelAtividade = 1.6;
                    break;
                case 3:
                    physicalAssessment.NivelAtividadeDescricao = 'Intenso';
                    physicalAssessment.ValorNivelAtividade = 1.9;
                    break;
                case 4:
                    physicalAssessment.NivelAtividadeDescricao = 'Extremo';
                    physicalAssessment.ValorNivelAtividade = 2.2;
                    break;
            }
        }

        if (physicalAssessment.PressaoArterial) {
            physicalAssessment.PressaoArterial = this.pressureMask(physicalAssessment.PressaoArterial);
        }

        if (physicalAssessment.ParQ1 || physicalAssessment.ParQ2 ||
            physicalAssessment.ParQ3 || physicalAssessment.ParQ4 ||
            physicalAssessment.ParQ5 || physicalAssessment.ParQ6 ||
            physicalAssessment.ParQ7) {
            physicalAssessment.ParQ = true;
        } else {
            physicalAssessment.ParQ = false;
        }
    }

    private pressureMask(pressure: string): string {
        if (pressure.length === 5) {
            pressure = pressure.slice(0, 3) + 'x' + pressure.slice(3);
        } else if (pressure.length === 4) {
            pressure = pressure.slice(0, 2) + 'x' + pressure.slice(2);
        }
        return pressure;
    }

    private getIdealBodyMass(physicalAssessment: IPhysicalAssessment): void {
        let massaIdeal = 0;
        if (physicalAssessment.Massa && physicalAssessment.Estatura) {
            if (physicalAssessment.PessoaSexo === 'M') {
                massaIdeal = Math.pow((physicalAssessment.Estatura / 100), 2) * 23;
                physicalAssessment.MassaIdealMin = massaIdeal - ((massaIdeal * 10) / 100);
                physicalAssessment.MassaIdealMax = massaIdeal + ((massaIdeal * 10) / 100);
            } else if (physicalAssessment.PessoaSexo === 'F') {
                massaIdeal = Math.pow((physicalAssessment.Estatura / 100), 2) * 21.5;
                physicalAssessment.MassaIdealMin = massaIdeal - ((massaIdeal * 10) / 100);
                physicalAssessment.MassaIdealMax = massaIdeal + ((massaIdeal * 10) / 100);
            }
        }
        physicalAssessment.MassaTotalAlvo = physicalAssessment.MassaTotalAlvo ? Number(physicalAssessment.MassaTotalAlvo.toFixed(0)) : 0;
        physicalAssessment.MassaIdealMin = physicalAssessment.MassaIdealMin ? Number(physicalAssessment.MassaIdealMin.toFixed(0)) : 0;
        physicalAssessment.MassaIdealMax = physicalAssessment.MassaIdealMax ? Number(physicalAssessment.MassaIdealMax.toFixed(0)) : 0;
        physicalAssessment.Massa = physicalAssessment.Massa ? Number(physicalAssessment.Massa.toFixed(0)) : 0;
    }

    private getFatPercentage(physicalAssessment: IPhysicalAssessment): void {
        let idealFatPercentage: string = '';
        if (physicalAssessment.GC) {
            if (physicalAssessment.PessoaIdade <= 29) {
                idealFatPercentage = physicalAssessment.PessoaSexo === 'M' ? '11 ~ 13' : '16 ~ 19';
            } else if (physicalAssessment.PessoaIdade >= 30 && physicalAssessment.PessoaIdade <= 39) {
                idealFatPercentage = physicalAssessment.PessoaSexo === 'M' ? '12 ~ 14' : '17 ~ 20';
            } else if (physicalAssessment.PessoaIdade >= 40 && physicalAssessment.PessoaIdade <= 49) {
                idealFatPercentage = physicalAssessment.PessoaSexo === 'M' ? '14 ~ 16' : '18 ~ 21';
            } else if (physicalAssessment.PessoaIdade >= 50) {
                idealFatPercentage = physicalAssessment.PessoaSexo === 'M' ? '15 ~ 17' : '19 ~ 22';
            }

            const splitIdeal = idealFatPercentage.split(' ~ ');

            physicalAssessment.PercentualGorguralIdealMin = parseInt(splitIdeal[0], 10)
            physicalAssessment.PercentualGorguralIdealMax = parseInt(splitIdeal[1], 10);
        }

    }

    private getVO2(physicalAssessment: IPhysicalAssessment, protocols: any): void {
        physicalAssessment.ProtocoloVO2Realizado = protocols.Descricao.split('.')[1];

        switch (physicalAssessment.ProtocoloVO2EstimadoId) {
            case 1:
                physicalAssessment.ProtocoloVO2EstimadoDescricao = 'Jackson, A.s. e col. (1990)';
                break;
            case 2:
                physicalAssessment.ProtocoloVO2EstimadoDescricao = 'Mathews et al. (1999/EUA)';
                break;
        }

        let capacityClassification: ICapacityRating[] = this.getCapacityRatingByGender(physicalAssessment);

        const capacityRating = findValueRange(capacityClassification, 'Idade', physicalAssessment.PessoaIdade);

        if (!capacityRating) return;

        const array: any[] = Object.entries(capacityRating);
        for (const [capacity, value] of array) {
            if (capacity !== 'Idade') {
                if (value.includes('-') && (physicalAssessment.VO2RealizadoResultado || 0) >= Number(value.split('-')[0]) && (physicalAssessment.VO2RealizadoResultado || 0) <= Number(value.split('-')[1])) {
                    physicalAssessment.VO2RealizadoClassificacao = capacity;
                } else if (value.includes('>=') && (physicalAssessment.VO2RealizadoResultado || 0) >= Number(value.split('>=')[1])) {
                    physicalAssessment.VO2RealizadoClassificacao = capacity;
                } else if (value.includes('<=') && (physicalAssessment.VO2RealizadoResultado || 0) <= Number(value.split('<=')[1])) {
                    physicalAssessment.VO2RealizadoClassificacao = capacity;
                }

                if (value.includes('-') && (physicalAssessment.VO2EstimadoResultado || 0) >= Number(value.split('-')[0]) && (physicalAssessment.VO2EstimadoResultado || 0) <= Number(value.split('-')[1])) {
                    physicalAssessment.VO2EstimadoClassificacao = capacity;
                } else if (value.includes('>=') && (physicalAssessment.VO2EstimadoResultado || 0) >= Number(value.split('>=')[1])) {
                    physicalAssessment.VO2EstimadoClassificacao = capacity;
                } else if (value.includes('<=') && (physicalAssessment.VO2EstimadoResultado || 0) <= Number(value.split('<=')[1])) {
                    physicalAssessment.VO2EstimadoClassificacao = capacity;
                }
            }
        }
    }

    public getCapacityRatingByGender(physicalAssessment: IPhysicalAssessment): ICapacityRating[] {
        if (physicalAssessment.PessoaSexo === 'M') {
            return MASCULINE_CONFIG;
        } else if (physicalAssessment.PessoaSexo === 'F') {
            return FEMININE_CONFIG;
        } else {
            return []
        }
    }

    public getRating(age: number, result: number, table: any[]): IRatingResult {
        const rate: IRating = this.getRatingGroupByAge(age, table);

        const { Idade, ...rating } = rate;
        return this.getRatingByResult(result, rating);
    }

    public getRatingByResult(result: number, rating: Record<string, string>): IRatingResult {
        const key = Object.keys(rating).find((key) => {
            const range = rating[key];

            if (range.includes('-')) {
                const [start, end] = range.split('-').map(Number);
                return result >= start && result <= end;
            }

            if (range.includes('<=')) {
                return result <= Number(range.split('<=')[1]);
            }

            if (range.includes('>=')) {
                return result >= Number(range.split('>=')[1]);
            }

            return false;
        }) as string;

        return { rating: key, range: rating[key] };
    }

    public getRatingGroupByAge(age: number, table: any[]): any {
        const matchingRange = table?.find((item) => {
            const [rangeInitialAge, rangeFinalAge] = item?.Idade?.includes('-') ? item?.Idade?.split('-').map(Number) : [0, 0];
            const rangeStartInclusive = item?.Idade?.includes('+') ? Number(item?.Idade?.split('+')[1]) : rangeInitialAge;

            return (
                (rangeFinalAge ?
                    age > rangeInitialAge && age < rangeFinalAge || age === rangeInitialAge || age === rangeFinalAge :
                    age >= rangeStartInclusive)
            );
        });

        return matchingRange;
    }

    private setState(endpoint: string, state: string) {
        const currentState = this.stateSubject$.value;
        this.stateSubject$.next({
          ...currentState,
          [endpoint]: state,
        });
    }

    private setData(endpoint: string, data: any) {
        const currentData = this.physicalAssessmentsSubject$.value;
        this.physicalAssessmentsSubject$.next({
          ...currentData,
          [endpoint]: data,
        });
    }

    public setInitialState() {
        this.stateSubject$.next({ ...this.initialState });
    }

    public resetAllValue() {
        this.physicalAssessmentsSubject$.next({ ...this.initialValue });
    }

    public resetFacade(): void {
        this.setInitialState();
        this.resetAllValue();
    }
}