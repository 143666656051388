import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { tapResponse } from "@ngrx/operators";
import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { setPropLoading, setPropLoaded, setPropError, withReqState, setPropInit } from "@shared/stores/prop-state.store";
import { pipe, tap, switchMap } from "rxjs";
import { AccessService } from "./infra/qr-code.service";
import { AlertStore } from "@shared/components/alert/data-access/alert.store";
import { Router } from "@angular/router";
import { EAlertTypes } from "@shared/components/alert/data-access/entities/alert.enum";

interface IQrCodeState {
    accessResult: object;
};

const initialState: IQrCodeState = {
    accessResult: {},
};

export const QrCodeStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        accessService = inject(AccessService),
        alertStore = inject(AlertStore),
        router = inject(Router),
    ) => {
        return {
            requireAccess: rxMethod<{ hardwareId: string, monitorId: string }>(pipe(
                tap(() => patchState(store, { ...setPropLoading('requireAccess') })),
                switchMap(({hardwareId, monitorId}) => {
                    return accessService.requireAccess(hardwareId, monitorId).pipe(
                        tapResponse({
                            next: (accessResult) => {
                                router.navigate(['/home']);

                                alertStore.openAlert({
                                    title: 'Enviado!',
                                    message: 'Sua identificação foi enviada.',
                                    type: EAlertTypes.SUCCESS,
                                    mode: 'alert',
                                });
                                
                                patchState(store, { accessResult, ...setPropLoaded('requireAccess') });
                            },
                            error: (error: HttpErrorResponse) => {
                                router.navigate(['/home']);

                                
                                alertStore.openAlert({
                                    title: 'Erro ao acessar',
                                    message: 'Não foi possível enviar sua identificação',
                                    type: EAlertTypes.DANGER,
                                    mode: 'alert',
                                });
                                patchState(store, { ...setPropError('requireAccess') });
                            }
                        })
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState, setPropInit('requireAccess'));
            }
        };
    }),
    withReqState('requireAccess'),
);