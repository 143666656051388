import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { RouterStore } from '@route/data-access';
import { AuthStore } from '@auth/data-access';
import { APP_CONFIG } from '@application/config';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

export const DomainGuard: CanActivateFn = () => {
  const authStore = inject(AuthStore);
  const routerStore = inject(RouterStore);
  const appConfig = inject(APP_CONFIG);
   if (!authStore.isLoggedIn()) {

     if (!Capacitor.isNativePlatform()) {
       location.href = appConfig.appUrl.accounts;
     }

     if (Capacitor.isNativePlatform()) {
       routerStore.navigate(['/onboarding']);
       Browser.open({ url: appConfig.appUrl.accounts }).then();
     }

   }
   return authStore.isLoggedIn();
};
