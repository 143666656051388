import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {map, pipe, switchMap, tap} from 'rxjs';
import { inject, untracked } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import * as cryptoJs from 'crypto-js';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app-auth/data-access/infra/auth.service';
import { IAccountStatus, IAuthAccessToken, IPersonByUser, IAuthPayload, IClientData, IClientDevice, IDeviceInfo, IConsentMode, ILoginCode } from '@app-auth/data-access/entities/auth.interface';
import { LocalStorageService } from '@app-services/localStorage.service';
import { Router } from '@angular/router';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
import { Device } from '@capacitor/device';
declare const gtag: Function;

import { EConsentMode } from './entities/auth.enum';
import { Capacitor } from '@capacitor/core';

interface IAuthState {
    AuthData: IAuthAccessToken | null;
    PeopleByUser: IPersonByUser[] | null;
    ClientData: IClientData | null;
    AccountStatus: IAccountStatus | null;
    DeviceInfo: IDeviceInfo | null;
    DeviceReady: boolean;
    LoginCode: ILoginCode | null;
    ConsentMode: IConsentMode | null;
    ShowConsentModal: boolean;
};

const initialState: IAuthState = {
    AuthData: null,
    PeopleByUser: null,
    ClientData: null,
    AccountStatus: null,
    DeviceInfo: null,
    DeviceReady: false,
    ConsentMode: null,
    ShowConsentModal: false,
    LoginCode: null,
};

export const AuthStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        authService = inject(AuthService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        localStorageService = inject(LocalStorageService),
        router = inject(Router),
    ) => {


        const registerFCM = (): void => {
            document.addEventListener('deviceready', () => {
                Device.getInfo().then((DeviceInfo) => {
                patchState(store, { DeviceInfo });
                }).then(() => {
                    Device.getId().then((deviceInfo) => {
                        patchState(store, {
                            DeviceInfo: { ...store.DeviceInfo(), Uuid: deviceInfo?.identifier },
                        });
                    }).then(() => {
                        patchState(store, {...setPropLoaded('deviceInfo')});

                    });
                });
            });
        }

        const setAuthData = (authData: IAuthAccessToken): void => {
            localStorageService.setItem('authorization', JSON.stringify(authData));
            registerFCM();
            patchState(store, { AuthData: authData });
        }

        const getAllPersonsByUser = rxMethod<void>(pipe(
            switchMap(() => authService.getAllPersonsByUser().pipe(
                tapResponse({
                    next: (PeopleByUser) => {
                        patchState(store, { PeopleByUser, ...setPropLoaded('AuthData') });
                        if (PeopleByUser.length > 1) {
                            router.navigate(['/login/accounts']);
                            return;
                        }

                        if (PeopleByUser.length === 0 || PeopleByUser.length === 1) {
                            router.navigate(['/home']);
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        patchState(store, { ...setPropError('AuthData', error) });
                    }
                })
            ))
        ))

        const initState = (): void => {
            patchState(store, {
                ...initialState,
                ...setPropInit(
                    'sendEmailForgotPassword',
                    'checkResetPasswordCode',
                    'resetPassword',
                    'checkAccountStatus',
                    'AuthData',
                    'confirmEmailAccountAndCreatePassword',
                    'deviceInfo',
                    'refreshClientDevice'
                ),
            });
        }

        const removeDevice = rxMethod<string>(pipe(
            switchMap((deviceId) => authService.removeClientDevice(deviceId).pipe(
                tapResponse({
                    next: () => {
                        patchState(store, { ...setPropLoaded('removeClientDevice') });
                    },
                    error: (error: HttpErrorResponse) => {
                        errorHandlerService.handleError(error);
                        patchState(store, { ...setPropError('removeClientDevice') });
                    }
                })
            ))
        ))

        const logout = (): void => {
            clearData();
            router.navigate(['/common/on-boarding']);
        }

        const clearData = () => {
            const deviceId = localStorageService.getItem('DeviceId');

            if (deviceId && store.DeviceReady()) {
                removeDevice(deviceId!);
            }

            initState();
            localStorageService.clearAllData();
        }


        const initConsentMode = (): IConsentMode => {
            const consentMode: IConsentMode = {
                'ad_storage': EConsentMode.DENIED,
                'ad_user_data': EConsentMode.DENIED,
                'ad_personalization': EConsentMode.DENIED,
                'analytics_storage': EConsentMode.DENIED
            };
            gtag('consent', 'default', consentMode);
            return consentMode;
        }

        const setIosConsent = (IosConsent: boolean) => {
            if (Capacitor.getPlatform() !== 'ios') return;
            const consentMode: IConsentMode = {
                'ad_storage': IosConsent? EConsentMode.GRANTED : EConsentMode.DENIED,
                'ad_user_data':  IosConsent ? EConsentMode.GRANTED : EConsentMode.DENIED,
                'ad_personalization': IosConsent? EConsentMode.GRANTED : EConsentMode.DENIED,
                'analytics_storage': IosConsent ? EConsentMode.GRANTED :  EConsentMode.DENIED
              };

              updateConsentMode(consentMode);
        }

        const updateConsentMode = (ConsentMode: IConsentMode) => {
            gtag('consent', 'update', ConsentMode);
            localStorageService.setItem('consentMode', JSON.stringify(ConsentMode));
            patchState(store, { ConsentMode, ShowConsentModal: false });
        }

        return {
            logout,

            clearData,
            setIosConsent,

            login: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Code'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((payload) => {
                    payload.Email = payload.Email.toLowerCase();
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));

                    return authService.login({ ...payload, AppSource: 'treino,actuar,facilfit' }).pipe(
                        tapResponse({
                            next: (authData) => {
                                setAuthData(authData);
                                getAllPersonsByUser();
                            },
                            error: (error: HttpErrorResponse) => {
                                if (error.status === 400) {
                                    patchState(store, { ...setPropError('AuthData', error) });
                                    return;
                                }
                                errorHandlerService.handleError(error);
                            }
                        })
                    );
                })
            )),

            choosePersonAccount: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((personId) => authService.choosePersonAccount(personId).pipe(
                    tapResponse({
                        next: (result) => {
                            patchState(store, { ...setPropLoaded('AuthData') });
                            setAuthData(result);
                            router.navigate(['/home']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('AuthData', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            getClient: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('getClient') })),
                switchMap((personId) => authService.getClient(personId).pipe(
                    tapResponse({
                        next: (response) => {
                            patchState(store, { ClientData: response, ...setPropLoaded('getClient') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('getClient', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            refreshClientDevice: rxMethod<IClientDevice>(pipe(
                tap(() => patchState(store, { ...setPropLoading('refreshClientDevice') })),
                switchMap((device) => authService.refreshClientDevice(device).pipe(
                    tapResponse({
                        next: (response) => {
                            if (response) {
                                console.log('Dispositivo atualizado');
                            }
                            localStorageService.setItem('DeviceId', response?.DeviceId!);
                            patchState(store, { DeviceInfo: {...store.DeviceInfo(), DeviceId: response?.DeviceId }, ...setPropLoaded('refreshClientDevice') });
                        },
                        error: (error: HttpErrorResponse) => {
                            console.error('Não foi possível atualizar o dispositivo');
                            patchState(store, { ...setPropLoaded('refreshClientDevice') });
                        }
                    })
                ))
            )),

            checkAccountStatus: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkAccountStatus') })),
                switchMap((email) => authService.checkAccountStatus(email).pipe(
                    tapResponse({
                        next: (accountStatus) => {
                            patchState(store, { AccountStatus: accountStatus, ...setPropLoaded('checkAccountStatus') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkAccountStatus', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            sendEmailForgotPassword: rxMethod<Pick<IAuthPayload, 'Email'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('sendEmailForgotPassword') })),
                switchMap((payload) => {
                    const success = () => {
                        alertStore.openAlert({
                            title: 'Código enviado por e-mail!',
                            message: 'Verifique o código para redefinir sua senha em seu e-mail.',
                            mode: 'alert',
                            type: EAlertTypes.SUCCESS,
                        });
                        patchState(store, { ...setPropLoaded('sendEmailForgotPassword') });

                        const base64 = window.btoa(JSON.stringify(payload));
                        router.navigate(['common/confirm-code', base64]);
                    }
                    return authService.sendEmailResetPassword(payload).pipe(
                        tapResponse({
                            next: success,
                            error: (error: HttpErrorResponse) => {
                                const errorEmail = error.error.errors['Email'];

                                if (errorEmail?.find((error: string) => error === 'Email de confirmação já foi enviado')) {
                                    success();
                                } else {
                                    patchState(store, { ...setPropError('sendEmailForgotPassword', error) });
                                    errorHandlerService.handleError(error);
                                }
                            }
                        })
                    )
                })
            )),

            checkResetPasswordCode: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Password'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkResetPasswordCode') })),
                switchMap((payload) => authService.checkResetPasswordCode(payload).pipe(
                    tapResponse({
                        next: () => {
                            const base64 = window.btoa(JSON.stringify(payload));
                            router.navigate(['common/redefine-password', base64]);
                            patchState(store, { ...setPropLoaded('checkResetPasswordCode') });
                            alertStore.openAlert({
                                title: 'Código confirmado!',
                                message: 'Redefina sua senha.',
                                mode: 'alert',
                                type: EAlertTypes.SUCCESS,
                            });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkResetPasswordCode', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            resetPassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('resetPassword') })),
                switchMap((payload) => {
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));
                    return authService.resetPassword(payload).pipe(
                        tapResponse({
                            next: () => {
                                patchState(store, { ...setPropLoaded('resetPassword') });
                                alertStore.openAlert({
                                    title: 'Senha alterada!',
                                    message: 'Sua senha foi alterada com sucesso.',
                                    mode: 'alert',
                                    type: EAlertTypes.SUCCESS,
                                });
                                router.navigate(['login']);
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('resetPassword', error) });
                                errorHandlerService.handleError(error);
                            }
                        })

                    )
                })
            )),

            initState,

            confirmEmailAccountAndCreatePassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('confirmEmailAccountAndCreatePassword') })),
                map((payload) => {
                  payload.Email = payload.Email.toLowerCase();
                  payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));
                  return payload;
                }),
                switchMap((payload) => authService.confirmEmailAccountAndCreatePassword(payload).pipe(
                    tapResponse({
                        next: () => {
                            patchState(store, { ...setPropLoaded('confirmEmailAccountAndCreatePassword') });
                            alertStore.openAlert({
                                title: 'Senha criada!',
                                message: 'Sua senha foi criada com sucesso.',
                                mode: 'alert',
                                type: EAlertTypes.SUCCESS,
                            });
                            router.navigate(['login']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('confirmEmailAccountAndCreatePassword', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),


            refreshToken: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('refreshToken') })),
                switchMap((TokenId) => authService.refreshToken(TokenId).pipe(
                    tapResponse({
                        next: (result) => {
                            setAuthData(result);
                            patchState(store, { ...setPropLoaded('refreshToken') });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('refreshToken') });
                        }
                    })
                ))
            )),

            toggleConsentModal: (show: boolean) => {
                patchState(store, { ShowConsentModal: show });
            },

            getConsentMode: () => {
                const isIos = Capacitor.getPlatform() === 'ios';
                const defaultConsentMode = initConsentMode();
                const consentModeString = localStorageService.getItem('consentMode');
                if (!consentModeString) {
                    localStorageService.setItem('consentMode', JSON.stringify(defaultConsentMode));
                    patchState(store, { ShowConsentModal: !isIos });
                    return;
                }
                const ConsentMode = JSON.parse(consentModeString);
                gtag('consent', 'update', ConsentMode);
                patchState(store, { ConsentMode });
            },

            updateConsentMode,

            generateLoginCode: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('generateLoginCode') })),
                switchMap(() => authService.generateLoginCode().pipe(
                    tapResponse({
                        next: (LoginCode) => {
                            patchState(store, { LoginCode, ...setPropLoaded('generateLoginCode') });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('generateLoginCode') });
                        }
                    })
                ))
            )),  
            
            loginByCodeTemp: rxMethod<{sid: string, uid: string}>(pipe(
                tap(() => patchState(store, { ...setPropLoading('loginByCodeTemp') })),
                switchMap(({ sid, uid }) => {
                    return authService.loginByCodeTemp({sid, uid}).pipe(
                        tapResponse({
                            next: (authData) => {
                                setAuthData(authData);
                                getAllPersonsByUser();
                                patchState(store, { ...setPropLoaded('loginByCodeTemp') });
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('loginByCodeTemp') });
                            }
                        })
                    );
                })
            )),
        }
    }),
    withReqState('AuthData', 'sendEmailForgotPassword', 'checkResetPasswordCode', 'resetPassword', 'checkAccountStatus', 'confirmEmailAccountAndCreatePassword', 'refreshToken', 'getClient', 'deviceInfo','refreshClientDevice', 'generateLoginCode', 'loginByCodeTemp'),
    withMethods((store, localStorageService = inject(LocalStorageService)) => {
        return {
            isMaster(): boolean {
                const authData = store.AuthData();
                return authData?.PersonId === authData?.TenantId;
            },
            isLoggedIn(): boolean {
                const isLoggedIn = !!localStorageService.getItem('authorization');
                if (!isLoggedIn) localStorageService.clearAllData();
                return isLoggedIn;
            }
        }
    }),
    withHooks({
        onInit(store, localStorageService = inject(LocalStorageService)) {
            const authData = localStorageService.getItem('authorization');
            if (authData) patchState(store, { AuthData: JSON.parse(authData) });
            document.addEventListener('deviceready', () => {
                patchState(store, { DeviceReady: true });
            });
        },
    }),
);
