export enum EPaguelaAccountStatus {
    ACTIVE = 0,
    INACTIVE = 1,
    BLOCKED = 2,
}

export enum ESourceType {
    SUBSCRIPTION,
    SALE,
    SERVICE
}

export enum ESourceTypeName {
    'Assinatura' = ESourceType.SUBSCRIPTION,
    'Venda' = ESourceType.SALE,
    'Serviço' = ESourceType.SERVICE,
}

export enum ESourceTypeCreateSale {
    ASSINATURA = ESourceType.SUBSCRIPTION,
    VENDA = ESourceType.SALE,
    SERVICO = ESourceType.SERVICE,
}

export enum ESourceSeparator {
    ' - ' = ESourceType.SUBSCRIPTION,
    ',' = ESourceType.SALE,
    'Qtd.' = ESourceType.SERVICE,
}

export enum EPaymentMethod {
    Pix,
    CARD,
}

export enum ECardColor {
    GREEN = 'green',
    PINK = 'pink',
}