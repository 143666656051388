import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class IconComponent implements OnInit {

  @Input() name: string | undefined;
  @Input() background: string;
  @Input() extension: string;
  @Input({ required: true }) height: string;
  @Input({ required: true }) width: string;

  ngOnInit() { }

}
