import { computed, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Router, UrlSerializer } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { filter } from 'rxjs';
import { IPresentationConfig, IPriorityStack } from '@app-presentation/data-access/presentation.model';
import { WorkoutStore } from '@app-training/data-access/workout.store';
import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';

interface IPresentationState {
    OpenMenu: boolean | null;
    CurrentUrl: string;
    PreviousUrl: string;
    ScrollElement: HTMLElement | null;
    NearEnd: boolean;
    IsFullScreen: boolean;
    IsReload: boolean;
    RouteTransitionRunning: boolean;
    PullToRefresh: boolean;
    PullToRefreshFinished: boolean;
    ResetPresentationState: boolean;
    InitPresentationState: boolean;
    PresentationConfig: IPresentationConfig;
    InfiniteScrollFinishedAllResults: boolean;
    Scrolled: boolean;
    HideHeaderbar: boolean;
    HideNavbar: boolean;
    ScrollData:  {
      toTop: boolean;
      toBottom: boolean;
      isScrolling: boolean;
    };
    PriorityStack: IPriorityStack[];
    CanGoBack: IPriorityStack;
}

const initialState: IPresentationState = {
    OpenMenu: null,
    CurrentUrl: '',
    PreviousUrl: '',
    ScrollElement: null,
    NearEnd: false,
    IsFullScreen: false,
    IsReload: false,
    PullToRefresh: false,
    PullToRefreshFinished: false,
    RouteTransitionRunning: false,
    InfiniteScrollFinishedAllResults: false,
    ResetPresentationState: false,
    InitPresentationState: false,
    PresentationConfig: { EnablePullToRefresh: false, EnableInfiniteScroll: false },
    Scrolled: false,
    HideHeaderbar: false,
    HideNavbar: false,
    ScrollData: {
      toTop: false,
      toBottom: false,
      isScrolling: false
    },
    PriorityStack: [],
    CanGoBack: { id: '', priority: 0 }
};

export const PresentationStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        router = inject(Router),
        urlSerializer = inject(UrlSerializer),
    ) => {

        const enterFullScreen = () => {
            const page: any = document.documentElement;
            page.requestFullscreen?.();
            page.mozRequestFullScreen?.();
            page.msRequestFullscreen?.();
            page.webkitRequestFullScreen?.();
            patchState(store, { IsFullScreen: true });
        }

        const exitFullScreen = () => {
            const _document: any = document;

            if (!_document.fullscreenElement) return;

            _document.exitFullscreen?.();
            _document.webkitExitFullscreen?.();
            _document.msExitFullscreen?.();
            _document.mozCancelFullScreen?.();

            patchState(store, { IsFullScreen: false });
        }

        const notifyCanGoBack = (highestPriorityComponent: IPriorityStack) => {
          if (highestPriorityComponent) {
            patchState(store, { CanGoBack: highestPriorityComponent });
          }
        }

        return {
            toggleMenu: (OpenMenu: boolean = !store.OpenMenu()) => {
                patchState(store, { OpenMenu });
            },

            back: () => {
                const url = router.url.split('/');
                const lastElement = url.pop();
                if ((lastElement?.includes('chat') || lastElement?.includes('add-card')) && store.PreviousUrl()) router.navigate([store.PreviousUrl()])
                else router.navigate(url);
            },

            createUrlTree: (route: string[], queryParams: Object) => {
                const tree = router.createUrlTree(route, { queryParams: { params: btoa(JSON.stringify(queryParams)) }, queryParamsHandling: 'merge' })
                const url = urlSerializer.serialize(tree);
                router.navigateByUrl(url);
            },

            setScrollElement: (element: HTMLElement) => {
                patchState(store, { ScrollElement: element });
            },
            enterFullScreen,
            exitFullScreen,
            toggleFullScreen: () => {
                if (store.IsFullScreen()) exitFullScreen();
                else enterFullScreen();
            },
            reload: (IsReload: boolean) => {
                patchState(store, { IsReload });
            },
            userRequestUpdate: (PullToRefresh: boolean) => {
              patchState(store, { PullToRefresh });
            },
            completeInfiniteScroll: (InfiniteScrollFinishedAllResults: boolean) => {
              patchState(store, {InfiniteScrollFinishedAllResults});
            },
            completePullToRefresh: (PullToRefreshFinished: boolean) => {
              patchState(store, {PullToRefreshFinished});
            },
            infiniteScroll: (Scrolled: boolean) => {
              patchState(store, { Scrolled });
            },
            setConfigPresentation: (PresentationConfig: IPresentationConfig) => {
              patchState(store, { PresentationConfig });
            },
            resetState: () => {
              const { PresentationConfig, PullToRefreshFinished, InfiniteScrollFinishedAllResults, ResetPresentationState, InitPresentationState, Scrolled  } = initialState;
              patchState(store, { PresentationConfig, PullToRefreshFinished, InfiniteScrollFinishedAllResults, ResetPresentationState, InitPresentationState, Scrolled});
            },
            removeHeaderbar: (HideHeaderbar: boolean) => {
              patchState(store, { HideHeaderbar });
            },
            removeNavbar: (HideNavbar: boolean) => {
              patchState(store, { HideNavbar });
            },
            setScrollData: (scrollData: { toTop: boolean; toBottom: boolean; isScrolling: boolean; } ) => {
              patchState(store, { ScrollData: scrollData });
            },
            handleBackButton() {
              const priorityStack = store.PriorityStack();

              if (priorityStack?.length > 0) {
                const componentToGoBack = priorityStack[0];
                notifyCanGoBack(componentToGoBack);

                const index = priorityStack.findIndex(c => c.id === componentToGoBack.id);
                if (index > -1) {
                  priorityStack.splice(index, 1);
                }

                patchState(store, { PriorityStack: priorityStack });
                return;
              } 
              history.back();
            },
            registerPriority(id: string, priority: number) {
              const orderedByPriority = store.PriorityStack();
              const exists = orderedByPriority.some((item: IPriorityStack) => item.id === id);
            
              if (!exists) {
                orderedByPriority.push({ id, priority });
                orderedByPriority.sort((a: IPriorityStack, b: IPriorityStack) => b.priority! - a.priority!);
         
                patchState(store, { PriorityStack: orderedByPriority });
              }
            },
            removePriority(id: string) {
              const priorityStack = store.PriorityStack();
              const index = priorityStack.findIndex((item: IPriorityStack) => item.id === id);
              if (index > -1) priorityStack.splice(index, 1);
              patchState(store, { PriorityStack: priorityStack });
            },

            resetStore(): void {
              patchState(store, initialState);
            }
        };
    }),
    withHooks({
        onInit(store, router = inject(Router), destroyRef = inject(DestroyRef), workoutStore = inject(WorkoutStore), platform = inject(Platform)) {
          workoutStore.removeOldTimeValues();

            patchState(store, { PreviousUrl: '' });
            patchState(store, { CurrentUrl: router.url });
          router.events.pipe(
            takeUntilDestroyed(destroyRef),
            filter(event => event instanceof NavigationStart),
          ).subscribe((event) => {
            patchState(store, { RouteTransitionRunning: true });
          });
            router.events.pipe(
                takeUntilDestroyed(destroyRef),
                filter(event => event instanceof NavigationEnd),
            ).subscribe((event) => {
              const navigationEndEvent = event as NavigationEnd;
              if (navigationEndEvent.url !== store.CurrentUrl()) {
                patchState(store, { RouteTransitionRunning: false });
                patchState(store, { PreviousUrl: store.CurrentUrl() });
                patchState(store, { CurrentUrl: navigationEndEvent.url });
                if (store.OpenMenu()) patchState(store, { OpenMenu: false });
                store.ScrollElement()?.scrollTo({
                    behavior: 'instant',
                    top: 0,
                    left: 0
                }
               );
              }
              if (!navigationEndEvent.url.includes('timer')) store.exitFullScreen();
            }
          );

          platform.ready().then(() => {
            App.addListener('backButton', () => {
              store.handleBackButton();
            });
          });
        },
    }),
    withComputed((store) => ({
        isWeb: computed(() => Capacitor.getPlatform() === 'web'),
    }))
);
