import { trigger, transition, animate, style, query, animateChild, group, keyframes, state } from '@angular/animations';

export const slideInAnimation = 
  trigger('routeAnimations', [
    transition((fromState, toState, _element, params) => {
      const indexFrom = ['Home', 'TrainingList', 'Training', 'Afig', 'ClassesPlanned', 'ScheduledClasses', 'Profile'].findIndex(route => route === fromState);
      const indexTo = ['Home', 'TrainingList', 'Training', 'Afig', 'ClassesPlanned', 'ScheduledClasses', 'Profile'].findIndex(route => route === toState);
      if ([fromState, toState].includes('void') || indexFrom < 0 || indexTo < 0) return false;
      return indexFrom > indexTo;
    }, [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ transform: 'translateX(-100%)' })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ transform: 'translateX(100%)' }))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
      ]),
    ]),
    transition((fromState, toState, _element, params) => {
      const indexFrom = ['Home', 'TrainingList', 'Training', 'Afig', 'ClassesPlanned', 'ScheduledClasses', 'Profile'].findIndex(route => route === fromState);
      const indexTo = ['Home', 'TrainingList', 'Training', 'Afig', 'ClassesPlanned', 'ScheduledClasses', 'Profile'].findIndex(route => route === toState);
      if ([fromState, toState].includes('void') || indexFrom < 0 || indexTo < 0) return false;
      return indexTo > indexFrom;
    }, [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ transform: 'translateX(100%)' })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
      ]),
    ]),
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        })
      ], { optional: true }),
      query(':enter', [style({ opacity: 0 })], { optional: true }),
      group([
        query(':leave', [animate('300ms ease-out', style({ opacity: 0}))], { optional: true }),
        query(':enter', [animate('300ms ease-out', style({ opacity: 1 }))], { optional: true })
      ]),
    ])
  ]);

export const slideY =
  trigger('fadeSlide', [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate(
        400,
        style({ transform: 'translateY(0)' })
      )
      ,
    ]),
    transition(':leave', [
      animate(
        400,
        style({ transform: 'translateY(100%)' })
      )
      ,
    ])
  ]);

export const slideX =
  trigger('fadeSlide', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate(
        400,
        style({ transform: 'translateX(0)' })
      )
      ,
    ]),
    transition(':leave', [
      animate(
        400,
        style({ transform: 'translateX(100%)' })
      )
      ,
    ])
  ]);

export const opacity =
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('300ms ease-out',
            style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('300ms ease-in',
            style({ opacity: 0 }))
        ]
      )
    ]
  );

export const fadeSwitch =
  trigger('fadeSwitch', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms', style({ opacity: 1 }))]
    ),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0ms', style({ opacity: 0 }))]
    )
  ])

export const partialOpacity =
  trigger(
    'partialOpacity',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('300ms ease-out',
            style({ opacity: 0.5 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 0.5 }),
          animate('300ms ease-in',
            style({ opacity: 0 }))
        ]
      )
    ]
  );
export const pulse =
  trigger('pulse', [
    transition((fromState, toState, _element, params) => params?.['target'] && !params?.['enterOrLeave'],
      [
        animate('500ms', keyframes([
          style({ transform: 'scale(0.5)', offset: 0 }),
          style({ transform: 'scale(1.5)', offset: 0.5 }),
          style({ transform: 'scale(1)', offset: 1 })
        ])),
      ]),
  ]);

export const opacityWithDelay =
  trigger(
    'opacityWithDelay',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('300ms 300ms ease-out',
            style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('300ms ease-in',
            style({ opacity: 0 }))
        ]
      )
    ]
  );

  export const backdropAnimation =
  trigger(
    'backdropAnimation',
    [
      transition(
        ':enter',
        [
          group([
            query(':self', [
              style({ opacity: 0 }),
              animate('200ms ease')
            ]),
            query('@slideUpAnimation', animateChild(), { optional: true }),
            query('@navInOutAnimationMobile', animateChild(), { optional: true }),
            query('@navInOutAnimationDesktop', animateChild(), { optional: true })
          ])
        ]
      ),
      transition(
        ':leave',
        [
          group([
            query(':self', [animate('200ms ease'), style({ opacity: 0 })]),
            query('@slideUpAnimation', animateChild(), { optional: true }),
            query('@navInOutAnimationMobile', animateChild(), { optional: true }),
            query('@navInOutAnimationDesktop', animateChild(), { optional: true })
          ])
        ]
      )
    ]
  );


export const navInOutAnimationMobile = [
  trigger('navInOutAnimationMobile', [
    transition(':enter', [
      style({ transform: 'translate3d(100%, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(0, 0, 0)' }))
    ]),
    transition(':leave', [
      style({ transform: 'translate3d(0, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(100%, 0, 0)' }))
    ])
  ])
];

export const navInOutAnimationDesktop = [
  trigger('navInOutAnimationDesktop', [
    transition(':enter', [
      style({ transform: 'translate3d(-100%, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(0, 0, 0)' }))
    ]),
    transition(':leave', [
      style({ transform: 'translate3d(0, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(-100%, 0, 0)' }))
    ])
  ])
];

export const counterInOutAnimation = [
  trigger('counterInOut', [
    transition(':enter', [
      style({ transform: 'translate3d(100%, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(0, 0, 0)' }))
    ]),
    transition(':leave', [
      style({ transform: 'translate3d(0, 0, 0)' }),
      animate('200ms ease-in-out', style({ transform: 'translate3d(100%, 0, 0)' }))
    ])
  ])
];

export const slideUpAnimation =
  trigger('slideUpAnimation', [
    state('void', style({ transform: 'translateY(100%)' })),
    transition(':enter', [
      animate('300ms ease', style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      animate('300ms ease', style({ transform: 'translateY(100%)' })),
    ])
  ]);


export const navbarAnimation =
  trigger('navbarAnimation', [
    state('void', style({ transform: 'translateY(100%)' })),
    transition(':enter', [
      animate('500ms ease', style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      animate('500ms ease', style({ transform: 'translateY(100%)' })),
    ])
  ]);


export const currentTimeAnimation =
  trigger('currentTimeAnimation', [
    transition(':enter', [
      animate('500ms', keyframes([
        style({ transform: 'translateY(100%)', offset: 0 }),
        style({ transform: 'translateY(0%)', offset: 1 }),
      ])),
    ])
  ]);

export const lastTimeAnimation =
  trigger('lastTimeAnimation', [
    transition(':leave', [
      animate('500ms', keyframes([
        style({ transform: 'translateY(0%)', offset: 0 }),
        style({ transform: 'translateY(-100%)', offset: 1 })
      ])),
    ])
  ]);


export const opacityLeave =
  trigger('opacityLeave', [
    transition(':leave', [
      animate('500ms', keyframes([
        style({ opacity: 1, offset: 0 }),
        style({ opacity: 0, offset: 1 })
      ])),
    ])
  ]);