import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { IGraduation } from './entities/graduation.interface';
import { inject } from '@angular/core';
import { GraduationService } from './infra/graduation.service';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { Router } from '@angular/router';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';

interface IGraduationState {
    Graduations: IGraduation[];
};

const initialState: IGraduationState = {
    Graduations: [],
};

export const GraduationStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        graduationService = inject(GraduationService),
        alertStore = inject(AlertStore),
        authStore = inject(AuthStore),
        router = inject(Router),
    ) => {
        return {
            getGraduations: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('getGraduations') })),
                switchMap(() => {
                    return graduationService.getLastGraduationsByPerson(authStore.AuthData()?.PersonId as string).pipe(
                        tapResponse({
                            next: (Graduations) => {
                                patchState(store, { Graduations, ...setPropLoaded('getGraduations') });
                            },
                            error: (error: HttpErrorResponse) => {
                                router.navigate(['/home']);

                                
                                alertStore.openAlert({
                                    title: 'Erro ao acessar',
                                    message: 'Não foi possível obter as graduações',
                                    type: EAlertTypes.DANGER,
                                    mode: 'alert',
                                });
                                patchState(store, { ...setPropError('getGraduations') });
                            }
                        })
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState, setPropInit('getGraduations'));
            }
        };
    }),
    withReqState('getGraduations'),
);