import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IGraduation } from '../entities/graduation.interface';

@Injectable({ providedIn: 'root' })
export class GraduationService {
    private http = inject(HttpClient);

    public getLastGraduationsByPerson(personId: string): Observable<IGraduation[]> {
        return this.http.get
          <IGraduation[]>(url(Protocol.https, EntityPath.graduation_service, `/PersonActivityLevel/GetLastGraduationsByPerson`), {
            params: { personId },
            withCredentials: true
          }
        );
      }

}
