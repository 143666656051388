import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FeedService } from '@app-feed/data-access/infra/feed.service';
import { iif, of, pipe, switchMap } from 'rxjs';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { IPost } from '@app-feed/data-access/entities/post.interface';
import { tapResponse } from "@ngrx/operators";
import { HttpErrorResponse } from '@angular/common/http';
import { sortByDateDescendingOrder } from '@shared/utils/sort-by-date.util';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';

interface IFeedState {
  reseting: boolean;
    Feed: {
        posts?: IPost[];
        taked: number;
        isFinished: boolean;
    };
}

const initialState: IFeedState = {
  reseting: false,
    Feed: {
        posts: [],
        taked: 0,
        isFinished: false,
    },
};

export const FeedStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        feedService = inject(FeedService),
        errorHandlerService = inject(ErrorHandlerService),
    ) => {
        return {
            getFeed: rxMethod<void>(pipe(
                switchMap(() => {
                  const pageItems = 5
                    const params = {
                        take: pageItems,
                        skip: store.Feed().taked * pageItems
                    }
                    return iif(
                        () => {
                            const canRequest = !store.Feed().isFinished;
                            if (canRequest) patchState(store, { ...setPropLoading('Feed') });
                            return canRequest;
                        },
                        feedService.getFeed(params).pipe(
                            tapResponse({
                                next: (response) => {
                                    const newPosts = sortByDateDescendingOrder(response, ['StartOnPost']);

                                    patchState(store, {
                                      reseting: false,
                                      Feed: {
                                          posts: store.reseting() ? newPosts : store.Feed().posts?.concat(newPosts),
                                          taked: store.Feed().taked + 1,
                                          isFinished: response.length < pageItems
                                      },
                                        ...setPropLoaded('Feed')
                                    });
                                },
                                error: (error: HttpErrorResponse) => {
                                    patchState(store, { ...setPropError('Feed') });
                                    errorHandlerService.handleError(error);
                                }
                            })
                        ),
                        of(null),
                    )
                }),
            )),

            initFeed: () => {
                patchState(store, initialState, { ...setPropLoading('Feed') });
            },

            clearConfigFeed: () => {
                patchState(store, { Feed: { isFinished: false, taked: 0}, reseting: true});
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('Feed'));
            }
        };
    }),
    withReqState('Feed'),
);
