import { inject, Component, effect } from '@angular/core';
import { AlertStore, IAlertItem } from '@alert/data-access';
import { trigger, transition, animate, style, query, group } from '@angular/animations';
import { AlertBoxComponent } from './alert-box/alert-box.component';

@Component({
  selector: 'lib-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  imports: [AlertBoxComponent],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms 200ms ease', style({ opacity: 1 }))]
      ),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease', style({ opacity: 0 }))]
      )
    ]),
    trigger('slideY', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms 200ms ease', style({ transform: 'translateY(0)' }))]
      ),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('200ms ease', style({ transform: 'translateY(-100%)' }))]
      )
    ]),
    trigger('slideX', [
      transition('* => *', [
        group([
          query(':leave', [
            style({ transform: 'translateX(0)' }),
            animate('200ms ease', style({ transform: 'translateX(100%)' }))
          ], { optional: true }),
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('200ms ease', style({ transform: 'translateX(0)' }))
          ], { optional: true }),
        ]),
      ]),
    ]),

  ],
})
export class AlertComponent {
  public readonly alertStore = inject(AlertStore);
  public alertList: IAlertItem[];
  public confirmList: IAlertItem[];

  constructor() {
    effect(() => {
      this.confirmList = this.alertStore.alertListEntities().filter((alert) => alert.mode === 'confirm' || alert.mode === 'option');
      this.alertList = this.alertStore.alertListEntities().filter((alert) => alert.mode === 'alert');
    });
  }
}
