import {Component, effect, inject, NgZone, untracked} from '@angular/core';
import {ChildrenOutletContexts, Router, RouterModule} from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { slideInAnimation } from 'src/shared/animations/animations';
import { LogoutComponent } from '@app-logout/feature/logout.component';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { PushNotificationsStore } from '@shared/stores/push-notifications.store';
import { IClientDevice } from '@app-auth/data-access/entities/auth.interface';
import { SegmentationStore } from './segmentation/segmentation.store';
import { GrowthBook } from '@growthbook/growthbook';
import { App, URLOpenListenerEvent } from '@capacitor/app';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, LogoutComponent],
  animations: [slideInAnimation],
})
export class AppComponent {
  public authStore = inject(AuthStore);
  public pushNotificationsStore = inject(PushNotificationsStore);
  private zone = inject(NgZone);
  private router = inject(Router);
  protected onDeviceInfoChange = effect(() => {
    const token = this.pushNotificationsStore?.Token();
    const authData = this.authStore?.AuthData();

    if (this.authStore.deviceInfoLoaded() && token && !this.authStore.refreshClientDeviceLoaded()) {
      untracked(() => {
        const deviceInfo = this.authStore.DeviceInfo();

        if (token && token.value) {
          const data: IClientDevice = {
            FcmToken: token.value,
            PersonId: authData?.PersonId,
            TenantId: authData?.TenantId,
            Manufacturer: deviceInfo?.manufacturer,
            Model: deviceInfo?.model,
            Platform: deviceInfo?.platform,
            Serial: null,
            UuId: deviceInfo?.Uuid,
            Version: deviceInfo?.webViewVersion,
          };

          this.authStore.refreshClientDevice(data);
        }
      });
    }
  });
  private segmentationStore = inject(SegmentationStore);

  constructor(private contexts: ChildrenOutletContexts) {
    this.segmentationStore.setGrowthBookInstance(window['_growthbook'] as GrowthBook);
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beta.trei.no/common/login/.....
        // slug = /common/login/.....
        const slug = event.url.split(".no").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }


  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
