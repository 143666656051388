import { ECardStatus, ETime } from "@app-activities/data-access/entities/activities.enum";

export interface IGroupDash {
    groupsDash: IGroup[];
    taked: number;
    scrolling?: boolean;
    isFinished: boolean;
    startOn?: string | null;
    endOn?: string | null;
    isOrderDescending?: boolean;
    setParticipantData(groups: IGroup[], personId: string): IGroup[];
    updateCurrentData(newGroups: IGroup[], isOrderDescending: boolean, isFinished: boolean): void;
}
export interface IGroup {
    Participants: {
        PersonId: string;
        ParticipantStatus: ECardStatus;
        Name: string;
        ParticipantId: string;
    }[];
    GroupId: string;
    OccurredOn: string;
    EndOccurredOn: string;
    Description: string;
    DurationInMinutes: number;
    VirtualGroupURL: string;
    SchedulingTimeLimit: number;
    NumberVacancies: number;
    NumberVacanciesAvailable: number;
    Color: string;
    IsFreeGroup: boolean;
    SpaceDescription: string;
    IsCanceled: boolean;
    NoteGroup: string;
    Teachers?: {
        PersonId: string;
        Name: string;
    }[];
    ParticipantId: string | null;
    ParticipantStatus: ECardStatus | null;
}

export interface IRequestChangeStatus {
    ParticipantId?: string | null;
    GroupId: string;
    OccurredOn: string;
    Status: ECardStatus;
    CanceledNote?: string;
    NumberVacanciesAvailable?: number;
    PersonId?: string;
}

export interface IRequestDeleteParticipant {
    ParticipantId: string;
    GroupId: string;
    OccurredOn: string;
}

export interface IResponseChangeStatus {
    ParticipantId: string;
    PersonId: string;
    PersonName: string;
    ParticipantStatus: ECardStatus
    CanceledNote: string;
    TenantId: string;
}

export interface IActivitySettings {
    TenantId: string;
    ReservationQuantityPerStudent: number;
    AllowCheckinWithoutReservation: boolean;
    AllowViewParticipants: boolean;
    TimeBeforeCheckinWithoutReservation: number;
    TimeTypeBeforeCheckinWithoutReservation: ETime;
    TimeLimitGroupCancellation: number;
    TimeTypeLimitGroupCancellation: number;
}

export class GroupDash implements IGroupDash {
    groupsDash: IGroup[];
    taked: number;
    scrolling: boolean;
    isFinished: boolean;
    startOn?: string | null;
    endOn?: string | null;
    isOrderDescending?: boolean;

    constructor({ groupsDash, taked, isFinished, startOn, endOn, isOrderDescending, scrolling }: Partial<IGroupDash>) {
        this.groupsDash = groupsDash ?? [];
        this.taked = taked ?? 0;
        this.scrolling = scrolling ?? false;
        this.isFinished = isFinished ?? false;
        this.startOn = startOn ?? null;
        this.endOn = endOn ?? null;
        this.isOrderDescending = isOrderDescending ?? false;
    }

    private groupsAreEqual(groupsDash: IGroup[], loadedGroups: IGroup[]): boolean {
        if (groupsDash?.length !== loadedGroups?.length) return false;

        for (let i = 0; i < groupsDash?.length; i++) {
            if (!this.objectsAreEqual(groupsDash[i], loadedGroups[i])) {
                return false;
            }
        }

        return true;
    }

    private objectsAreEqual(obj1: any, obj2: any): boolean {
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1?.length !== keys2?.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2?.includes(key)) {
                return false;
            }
            if (!this.objectsAreEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    setParticipantData(groups: IGroup[], personId: string): IGroup[] {
        return groups.map(group => {
            const participant = group.Participants?.find(participant => participant.PersonId === personId);
            return {
                ...group,
                ParticipantId: group.ParticipantId ?? participant?.ParticipantId ?? null,
                ParticipantStatus: group.ParticipantStatus ?? participant?.ParticipantStatus ?? null,
            };
        });
    }

    updateCurrentData(loadedGroups: IGroup[], isOrderDescending: boolean, isFinished: boolean): void {
        const orderChanged = isOrderDescending !== this.isOrderDescending;

        let data = this.groupsDash.concat(loadedGroups);

        if (this.groupsAreEqual(this.groupsDash, loadedGroups) || orderChanged) {
            data = loadedGroups;
        }

        this.groupsDash = data;
        this.taked += 1;
        this.isFinished = isFinished;
        this.isOrderDescending = isOrderDescending;
    }

}