import { ApplicationRef, ComponentRef, computed, createComponent, inject, Type } from '@angular/core';
import { ITimer, ITimerConfig } from '@app-timers/data-access/entities/timers.interface';
import { LocalStorageService } from '@app-services/localStorage.service';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { ETimerId } from '@app-timers/data-access/entities/timers.enum';
import { ModalTimerComponent } from '@app-timers/feature/modal-timer/modal-timer.component';

interface ITimersState {
    [ETimerId.TABATA]: ITimer;
    [ETimerId.EMOM]: ITimer;
    [ETimerId.AMRAP]: ITimer;
    [ETimerId.FOR_TIME]: ITimer;
    Sounds: {
        start: HTMLAudioElement;
        rest: HTMLAudioElement;
        done: HTMLAudioElement;
    },
    ComponentRef: ComponentRef<ModalTimerComponent> | null;
};

const initialState: ITimersState = {
    [ETimerId.FOR_TIME]: {
        Id: ETimerId.FOR_TIME,
        Title: 'Timer',
        Subtitle: 'For Time',
        Countdown: false,
        Config: {
            Time: '0100',
            Alarm: false,
        }
    },
    [ETimerId.AMRAP]: {
        Id: ETimerId.AMRAP,
        Title: 'Timer',
        Subtitle: 'AMRAP',
        Countdown: true,
        Config: {
            Time: '0100',
            Alarm: false,
        }
    },
    [ETimerId.TABATA]: {
        Id: ETimerId.TABATA,
        Title: 'Tabata',
        Subtitle: 'HIIT',
        Countdown: true,
        Config: {
            Time: '0020',
            TimeRest: '0010',
            Rounds: 8,
            Alarm: false,
        }
    },
    [ETimerId.EMOM]: {
        Id: ETimerId.EMOM,
        Title: 'EMOM',
        Countdown: true,
        Config: {
            Time: '0100',
            Rounds: 10,
            Alarm: false,
        }
    },
    Sounds: {
        start: new Audio('./assets/audios/start.mp3'),
        rest: new Audio('./assets/audios/rest.mp3'),
        done: new Audio('./assets/audios/done.mp3')
    },
    ComponentRef: null,
};

export const TimersStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        localStorageService = inject(LocalStorageService),
        appRef = inject(ApplicationRef)
    ) => {

        const setConfig = (Config: ITimerConfig, timerId: ETimerId) => {
            localStorageService.setItem(`timers/${timerId}`, JSON.stringify(Config));
            patchState(store, { [timerId]: { ...store[timerId](), Config } });
        }

        const blankState = () => {
            patchState(store, initialState);
        }

        return {
            setConfig(config: ITimerConfig, timerId: ETimerId): void {
                setConfig(config, timerId);
            },
            getConfig(timerId: string): ITimerConfig {
                return JSON.parse(localStorageService.getItem(`timers/${timerId}`) as string);
            },

            playStart(hasAlarm: boolean) {
                this.stopAllSounds();

                if (hasAlarm) {
                    store.Sounds().start.play();
                }
            },

            playDone() {
                this.stopAllSounds();
                store.Sounds().done.play();
            },

            playRest() {
                this.stopAllSounds();
                store.Sounds().rest.play();
            },

            stopAllSounds() {
                this.stop(store.Sounds().start);
                this.stop(store.Sounds().done);
                this.stop(store.Sounds().rest);
            },

            stop(sound: HTMLAudioElement) {
                sound.pause();
                sound.currentTime = 0;
            },

            openModalTimer(Time: string) {
                const modalTimer: Type<ModalTimerComponent> = ModalTimerComponent;
                setConfig({ Time: Time.toString() }, ETimerId.AMRAP);

                patchState(store, {
                    ComponentRef: createComponent(modalTimer as Type<ModalTimerComponent>, {
                        environmentInjector: appRef.injector,
                    }),
                });
          
                document.body.appendChild(store.ComponentRef()!.location.nativeElement);
                appRef.attachView(store.ComponentRef()!.hostView);
            },
    
            removeModalTimer() {
                store.ComponentRef()?.destroy();
                blankState();
            },

            formatTimeForMinutes(time: string): string {
                const parsedTime = parseInt(time, 10);
                return parsedTime <= 9 ? `0${parsedTime}00` : `${parsedTime}00`;
            },
              
            formatTimeForSeconds(time: string): string {
                const parsedTime = parseInt(time, 10);
                return parsedTime <= 9 ? `000${parsedTime}` : `00${parsedTime}`;
            },

            resetStore(): void {
                patchState(store, initialState);
            }
        }
    }),
    withComputed((store) => {
        return {
            getTimers: computed(() => [store[ETimerId.FOR_TIME](), store[ETimerId.AMRAP](), store[ETimerId.TABATA](), store[ETimerId.EMOM]()])
        }
    }),
    withHooks({
        onInit(store) {
            store.getTimers().forEach(timer => {
                const config = store.getConfig(timer.Id);
                if (config) patchState(store, { [timer.Id]: { ...timer, Config: config } });
            });
        },
    })
)