import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from "@ngrx/operators";
import { concatMap, iif, of, pipe, switchMap, tap } from 'rxjs';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserPhoto } from '@shared/components/user-photo/data-access/entities/user-photo.interface';
import { UserPhotosService } from './infra/user-photos.service';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { setPropError, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';

interface IUserPhotoState {
    UserPhotoById: Record<string, IUserPhoto | null>;
    UserPhotoByIdState: Record<string, 'loading' | 'loaded' | 'error'>;
};

const initialState: IUserPhotoState = {
    UserPhotoById: {},
    UserPhotoByIdState: {},
};

export const UserPhotoStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        alertStore = inject(AlertStore),
        userPhotosService = inject(UserPhotosService),
        errorHandlerService = inject(ErrorHandlerService),
    ) => {

        const setFlagAsyncToTreino = rxMethod<IUserPhoto>(pipe(
            switchMap((userPhoto) => userPhotosService.setFlagAsyncToTreino(String(userPhoto.FileId)).pipe(
                tapResponse({
                    next: () => {
                        alertStore.openAlert({
                            type: EAlertTypes.SUCCESS,
                            title: 'Alteração realizada!',
                            message: 'Sua foto foi atualizada com sucesso.',
                        });
                        userPhoto.File.Url = `${userPhoto.File.Url}?${new Date().getTime()}`;
                        patchState(store, { UserPhotoById: { ...store.UserPhotoById(), [userPhoto.PersonId || '']: userPhoto }, UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userPhoto.PersonId!]: 'loaded' } });
                    },
                    error: (error: HttpErrorResponse) => {
                        patchState(store, { UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userPhoto.PersonId!]: 'error' } });
                        errorHandlerService.handleError(error);
                    }
                })
            ))
        ));

        return {
            getUserPhotoById: rxMethod<string>(pipe(
                concatMap((userId) => iif(
                    () => {
                        const canRequest = !store.UserPhotoByIdState()[userId] || store.UserPhotoByIdState()[userId] === 'error';
                        if (canRequest) patchState(store, { UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userId]: 'loading' } });
                        return canRequest;
                    },
                    userPhotosService.getUserPhotosById(userId).pipe(
                        tapResponse({
                            next: (userPhotos) => {
                                patchState(store, { UserPhotoById: { ...store.UserPhotoById(), [userId]: userPhotos[0] ?? {} }, UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userId]: 'loaded' } });
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userId]: 'error' } });
                                // errorHandlerService.handleError(error);
                            }
                        }),
                    ),
                    of(null)
                ))
            )),

            newUserPhoto: rxMethod<IUserPhoto>(pipe(
                tap((userPhoto) => patchState(store, { UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userPhoto.PersonId!]: 'loading' } })),
                switchMap((userPhoto) => {
                    const request = userPhoto.FileId ? userPhotosService.putUserPhoto(userPhoto) : userPhotosService.postUserPhoto(userPhoto);
                    return request.pipe(
                        tapResponse({
                            next: (userPhoto) => setFlagAsyncToTreino(userPhoto),
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { UserPhotoByIdState: { ...store.UserPhotoByIdState(), [userPhoto.PersonId!]: 'error' } });
                                errorHandlerService.handleError(error);
                            }
                        })
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState);
            }
        };
    })
);