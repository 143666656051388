import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IAboutGym, IOperationPeriods } from "@app-about-gym/data-access/entities/about-gym.interface";

@Injectable({
  providedIn: 'root'
})
export class AboutGymService {
  private http = inject(HttpClient);

  public getAboutGym(): Observable<IAboutGym> {
    return this.http.get<IAboutGym>(url(Protocol.https, EntityPath.workout_service, '/WorkoutSettings/GetWorkoutSettings'), {
      withCredentials: true
    });
  }

  public getOperationPeriod(): Observable<IOperationPeriods[]> {
    return this.http.get<IOperationPeriods[]>(url(Protocol.https, EntityPath.account_service, '/OperationPeriod/GetOperationsPeriodsDescriptions'), {
      withCredentials: true
    });
  }
}
