import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityPath, Protocol, url } from '@environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AccessService {
  private http = inject(HttpClient);
  
  public requireAccess(hardwareId: string, monitorId: string): Observable<object> {
    return this.http.get(url(Protocol.https, EntityPath.acesso, '/Manager/SolicitacaoAcesso?hardwareId=' + hardwareId + '&monitorId=' + monitorId), {
      withCredentials: true
    });
  }
}
