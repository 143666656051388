import { Component, inject, input, Input, InputSignal, OnInit } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { LoadingComponent } from '../loading/loading.component';
import { IonicModule } from '@ionic/angular';
import { PresentationStore } from '@app-presentation/data-access/presentation.store';
import { NgClass } from '@angular/common';

type TButton = 'primary' | 'secondary';

const eButtonStyles: Record<TButton, string> = {
  primary: 'text-black bg-green-treino',
  secondary: 'text-title-secondary bg-transparent',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [IconComponent, LoadingComponent, IonicModule, NgClass]
})
export class ButtonComponent {
  public readonly buttonStyle: InputSignal<TButton> = input<TButton>('primary');
  public readonly eButtonStyles = eButtonStyles;
  public readonly presentationStore = inject(PresentationStore);
  public readonly loading = input<boolean>(false);
  public readonly disabled = input<boolean>(false);
  public readonly title = input<string>();
  public readonly iconName = input<string>();
  public readonly iconExtension = input<string>('.svg');
}
