<app-slide-up
[title]="'Sair do Trei.no'"
[subtitle]="'Você tem certeza que deseja sair?'"
[show]="logoutStore.ShowDialog()"
(showChange)="logoutStore.toggleLogoutDialog($event)"
>
  <div class="w-full flex justify-between gap-3 whitespace-nowrap mt-[14px]">
    <button type="button" class="w-1/2 px-8 h-14 border-[#283850] border-[1px] border-solid rounded-6xl font-bold font-Satoshi text-center text-content-lg text-white py-3.5" (click)="logoutStore.toggleLogoutDialog(false)">
      Cancelar
    </button>

    <app-button class="w-1/2" [title]="'Sair'" (click)="logout()"></app-button>
  </div>
</app-slide-up>