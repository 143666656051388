<div #alertBox class="overflow-hidden relative rounded-lg bg-gradient-alert shadow-[0px_8px_10px_0px_rgba(0,0,0,0.20),0px_6px_30px_0px_rgba(0,0,0,0.12),0px_16px_24px_0px_rgba(0,0,0,0.14)] w-full">
  <div class="relative gap-4 w-full flex items-center py-3 px-4">
    <div class="flex items-center justify-center">
      <div class="absolute h-52 w-52 rounded-full bg-center"
            [ngClass]="{
                'bg-gradient-radial-alert-warning': alert()?.type === 'warning',
                'bg-gradient-radial-alert-success': alert()?.type === 'success',
                'bg-gradient-radial-alert-danger' : alert()?.type === 'danger',
                'bg-gradient-radial-alert-info' : alert()?.type === 'info'
                 }">
      </div>

      <div class="flex justify-center items-center h-10 w-10 rounded-full bg-[#303746]">
        @if (alert()?.type === 'warning') {
          <div class="flex justify-center items-center bg-[#FFD21E] w-6 h-6 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -mt-[0.1rem]" fill="#3D3D36" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
              <path d="M23.64,18.1L14.24,2.28c-.47-.8-1.3-1.28-2.24-1.28s-1.77,.48-2.23,1.28L.36,18.1h0c-.47,.82-.47,1.79,0,2.6s1.31,1.3,2.24,1.3H21.41c.94,0,1.78-.49,2.24-1.3s.46-1.78-.01-2.6Zm-1.72,1.6c-.05,.09-.2,.29-.51,.29H2.59c-.31,0-.46-.21-.51-.29s-.15-.32,0-.59L11.49,3.29c.15-.26,.41-.29,.51-.29s.35,.03,.51,.3l9.4,15.82c.16,.26,.05,.5,0,.59ZM11,8h2v6h-2v-6Zm0,8h2v2h-2v-2Z"/>
            </svg>
          </div>
        }

        @if (alert()?.type === 'success') {
          <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 rounded-full" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_859_20232" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" >
              <rect y="0.5" class="w-full h-full" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_859_20232)">
              <path d="M10.6 17.1L17.65 10.05L16.25 8.65L10.6 14.3L7.75 11.45L6.35 12.85L10.6 17.1ZM12 22.5C10.6167 22.5 9.31667 22.2373 8.1 21.712C6.88333 21.1873 5.825 20.475 4.925 19.575C4.025 18.675 3.31267 17.6167 2.788 16.4C2.26267 15.1833 2 13.8833 2 12.5C2 11.1167 2.26267 9.81667 2.788 8.6C3.31267 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.81233 8.1 3.287C9.31667 2.76233 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.76233 15.9 3.287C17.1167 3.81233 18.175 4.525 19.075 5.425C19.975 6.325 20.6873 7.38333 21.212 8.6C21.7373 9.81667 22 11.1167 22 12.5C22 13.8833 21.7373 15.1833 21.212 16.4C20.6873 17.6167 19.975 18.675 19.075 19.575C18.175 20.475 17.1167 21.1873 15.9 21.712C14.6833 22.2373 13.3833 22.5 12 22.5Z" fill="#00DF80"/>
            </g>
          </svg>
        }

        @if (alert()?.type === 'danger') {
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 rounded-full" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_1774_149" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
              <rect y="0.5" class="w-full h-full" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1774_149)">
              <path d="M8.4 17.5L12 13.9L15.6 17.5L17 16.1L13.4 12.5L17 8.9L15.6 7.5L12 11.1L8.4 7.5L7 8.9L10.6 12.5L7 16.1L8.4 17.5ZM12 22.5C10.6167 22.5 9.31667 22.2373 8.1 21.712C6.88333 21.1873 5.825 20.475 4.925 19.575C4.025 18.675 3.31267 17.6167 2.788 16.4C2.26267 15.1833 2 13.8833 2 12.5C2 11.1167 2.26267 9.81667 2.788 8.6C3.31267 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.81233 8.1 3.287C9.31667 2.76233 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.76233 15.9 3.287C17.1167 3.81233 18.175 4.525 19.075 5.425C19.975 6.325 20.6873 7.38333 21.212 8.6C21.7373 9.81667 22 11.1167 22 12.5C22 13.8833 21.7373 15.1833 21.212 16.4C20.6873 17.6167 19.975 18.675 19.075 19.575C18.175 20.475 17.1167 21.1873 15.9 21.712C14.6833 22.2373 13.3833 22.5 12 22.5Z" fill="#F04248"/>
            </g>
          </svg>
        }

        @if (alert()?.type === 'info') {
          <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 rounded-full" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1774_668)">
              <path d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM13 17.5H11V15.5H13V17.5ZM13 13.5H11V7.5H13V13.5Z" fill="#2196F3"/>
            </g>
            <defs>
              <clipPath id="clip0_1774_668">
                <rect class="w-full h-full" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        }
      </div>

    </div>
    <div class="font-Satoshi flex flex-col justify-center h-full w-[calc(100%_-_2.5rem_-_1rem)]">
      @if (alert()?.title) {
        <h1 class="leading-6 text-title-sm font-bold"> {{ alert()?.title }} </h1>
      }
      <p [ngClass]="alert()?.title ? 'text-content-sm' : 'text-sm'" class="leading-4 text-[#C8C5C5]"> {{ alert()?.message }}</p>
    </div>
  </div>
  <div #bar class="h-0.5"
    [ngClass]="{
        'bg-[#FFD426]': alert()?.type === 'warning',
        'bg-[#00DF80]': alert()?.type === 'success',
        'bg-[#F04248]': alert()?.type === 'danger',
        'bg-[#2196F3]': alert()?.type === 'info'
         }"></div>
</div>
