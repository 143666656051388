import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
})
export class LoadingComponent implements OnInit {

  @Input() public height: string;
  @Input() public width: string;
  @Input() public color: string;

  ngOnInit() { }

}
