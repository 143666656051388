import { CurrencyPipe, NgClass } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { BrandCardComponent } from "@shared/components/brandcard/brandcard.component";
import { AboutGymStore } from "@app-about-gym/data-access/about-gym.store";
import { opacity } from "src/shared/animations/animations";
import { delay, of } from "rxjs";
import { backdropAnimation } from "src/shared/animations/animations";
import { PaymentsStore } from "@app-payments/data-access/payments.store";
import { Router } from "@angular/router";
import { ButtonComponent } from "@shared/components/button/button.component";
@Component({
    selector: 'app-payment-voucher',
    templateUrl: './payment-voucher.component.html',
    styleUrls: ['./payment-voucher.component.scss'],
    standalone: true,
    animations: [backdropAnimation],
    imports: [NgClass, CurrencyPipe, BrandCardComponent, ButtonComponent],
})
export class PaymentVoucherComponent implements OnInit {
    public readonly paymentsStore = inject(PaymentsStore);
    public readonly aboutGymStore = inject(AboutGymStore);
    public readonly router = inject(Router);

    public ngOnInit(): void {
        if (!this.aboutGymStore.AboutGymLoaded()) {
            this.aboutGymStore.getAboutGym();
        }
    }

    public delay(ms: number) {
        return of(null).pipe(delay(ms));
    }

    public finish(): void {
        this.paymentsStore.clearVoucherAndErrorPayment();
        this.delay(500).subscribe(() => {
            this.paymentsStore.removeComponent();
            this.router.navigate(['/home']);
        });
    }
}
