import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { GrowthBook } from '@growthbook/growthbook';

type SegmentationState = {
  growthBookInstance: GrowthBook | null;
  isReady: boolean
}

export const SegmentationInitialState: SegmentationState = {
   growthBookInstance: null,
   isReady: false
}

export const SegmentationStore = signalStore(
    { providedIn: 'root' },
    withState(SegmentationInitialState),
    withMethods((store) => {
      return {
        getGrowthBookInstance: () => {
            return store.growthBookInstance;
        },
        setGrowthBookInstance: (growthBookInstance: GrowthBook) => {
           patchState(store,{ growthBookInstance: growthBookInstance });
        },
        checkFeature: (flag: string) => {
            return store.growthBookInstance()?.isOn(flag);
        },
        checkMultipleFeatures: (firstFeature: any, ...restOfFeatures: string[]) => {
          if (store.growthBookInstance())
            return store.growthBookInstance()?.getFeatureValue(firstFeature, restOfFeatures);
          return null;
        },
        setUserId: (userId: string) => {
          store.growthBookInstance()?.setAttributes({
            id: userId
          });
          patchState(store, {isReady: true})
        },
        resetStore(): void {
          patchState(store, SegmentationInitialState);
        }
      }
    }
))
