import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { iif, of, pipe, switchMap, tap } from 'rxjs';
import { IChangePaswordRequest, IUser, IUserPayload } from '@app-profile/data-access/entities/profile.interface';
import { ProfileService } from '@app-profile/data-access/infra/profile.service';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import * as cryptoJs from 'crypto-js';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
interface IProfileState {
    UserData: IUser | null;
};

const initialState: IProfileState = {
    UserData: null,
};

export const ProfileStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        authStore = inject(AuthStore),
        profileService = inject(ProfileService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
    ) => {
        return {
            getUserData: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('UserData') })),
                switchMap(() => iif(
                    () => !store.UserData(),
                    profileService.getUserData().pipe(
                        tapResponse({
                            next: (value) => {
                             patchState(store, { UserData: value, ...setPropLoaded('UserData') })},
                            error: (error) => patchState(store, { UserData: null, ...setPropError('UserData')  })
                        })
                    ),
                    of(null),
                ))
            )),

            changeSelfUserPassword: rxMethod<Omit<IChangePaswordRequest, 'Email'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('ChangePassword') })),
                switchMap((payload) => {
                    payload.NewPassword = String(cryptoJs.MD5(store.UserData()?.Email + payload.NewPassword));
                    payload.OldPassword = String(cryptoJs.MD5(store.UserData()?.Email + payload.OldPassword));

                    return profileService.changeSelfUserPassword({ ...payload, Email: store.UserData()?.Email! }).pipe(
                        tapResponse({
                            next: (value) => {
                                alertStore.openAlert({ title: 'Sucesso!', message: 'Sua senha foi alterada.', mode: 'alert', type: EAlertTypes.SUCCESS });
                                patchState(store, { ...setPropLoaded('ChangePassword') });
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('ChangePassword') });
                            }
                        })
                    )
                }),
            )),

            updateUserData: rxMethod<Omit<IUserPayload, 'PessoaId'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('UpdateUserData') })),
                switchMap((payload) => profileService.updateUserData({ ...payload, PessoaId: authStore.AuthData()?.PersonId! }).pipe(
                    tapResponse({
                        next: ({ DataNascimento, Sexo, PrimeiroNome, Sobrenome, Email }) => {
                            alertStore.openAlert({ title: 'Sucesso!', message: 'Seus dados foram atualizados.', mode: 'alert', type: EAlertTypes.SUCCESS });
                            patchState(store, {
                                UserData: {
                                    ...store.UserData()!,
                                    Birthdate: DataNascimento!,
                                    Email: Email!,
                                    FirstName: PrimeiroNome,
                                    LastName: Sobrenome,
                                    Sexo: Sexo!
                                },
                                ...setPropLoaded('UpdateUserData')
                            });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('UpdateUserData') });
                        }
                    })
                ))
            )),

            clearUserData: () => {
                patchState(store, { UserData: null }, setPropInit(
                    'UserData'
                ));
            },

            clearStore() {
                patchState(store, initialState, setPropInit(
                    'ChangePassword', 'updateUserData'
                ))
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('ChangePassword', 'updateUserData', 'UserData'));
            }
        }
    }),
    withReqState('ChangePassword', 'updateUserData', 'UserData')
);
