import { IBodyComposition } from "@app-models/entities/physical-assessments/body-composition.interface";
import { ICapacityRating } from "@app-models/entities/physical-assessments/physical-assessments.interface";

export const MASCULINE_CONFIG: ICapacityRating[] = [
  {
    Idade: '20-29',
    Fraca: '<=37',
    Regular: '38-41',
    Boa: '42-44',
    Excelente: '45-48',
    Superior: '>=49',
  },
  {
    Idade: '30-39',
    Fraca: '<=35',
    Regular: '36-39',
    Boa: '40-42',
    Excelente: '43-47',
    Superior: '>=48',
  },
  {
    Idade: '40-49',
    Fraca: '<=33',
    Regular: '34-37',
    Boa: '38-40',
    Excelente: '41-44',
    Superior: '>=45',
  },
  {
    Idade: '50-59',
    Fraca: '<=30',
    Regular: '31-34',
    Boa: '35-37',
    Excelente: '38-41',
    Superior: '>=42',
  },
  {
    Idade: '60-69',
    Fraca: '<=26',
    Regular: '27-30',
    Boa: '31-34',
    Excelente: '35-38',
    Superior: '>=39',
  },
];

export const FEMININE_CONFIG: ICapacityRating[] = [
  {
    Idade: '20-29',
    Fraca: '<=29',
    Regular: '32-34',
    Boa: '35-37',
    Excelente: '38-41',
    Superior: '>=42',
  },
  {
    Idade: '30-39',
    Fraca: '<=29',
    Regular: '30-32',
    Boa: '33-35',
    Excelente: '36-39',
    Superior: '>=40',
  },
  {
    Idade: '40-49',
    Fraca: '<=27',
    Regular: '28-30',
    Boa: '31-32',
    Excelente: '33-36',
    Superior: '>=37',
  },
  {
    Idade: '50-59',
    Fraca: '<=24',
    Regular: '25-27',
    Boa: '28-29',
    Excelente: '30-32',
    Superior: '>=33',
  },
  {
    Idade: '60-69',
    Fraca: '<=23',
    Regular: '25-24',
    Boa: '27-26',
    Excelente: '31-28',
    Superior: '>=32',
  },
];

export const BODY_COMPOSITION_CONFIG: IBodyComposition = {
  physicalAssessmentId: '',
  ProtocoloComposicaoCorporal: '',
  DescProtocoloComposicao: '',
  DescResumidaProtocoloComposicao: '',
  IMC: '',
  TaxaMetaBasal: '',
  GastoEnergeticoTotal: 0,
  TaxaMetabolicaBasal: 0,
  GC: 0,
  Estatura: 0,
  Massa: 0,
}