@if (alertItem) {
<div id="0FCEE0B6-FA0D" [ngClass]="{
      'mb-3.5': alertItem.mode === alertModes.ALERT,
    }" class="w-80 dark:bg-[#15181a] bg-white flex items-center relative z-[53] rounded-md px-4 py-6 shadow-box-model overflow-hidden">
    <i (click)="alertStore.cancel(alertItem.id)"
    class="cursor-pointer fi fi-rr-cross absolute text-white z-10 top-2 right-2 text-[0.5rem]"></i>
  @if (alertItem.mode === alertModes.ALERT) {
    @if (alertItem.iconName) {
    <ng-container id="4BF81EC0-4AAF">
      <lib-hero-icons id="0616100D-4CB7" [size]="5" [iconName]="alertItem.iconName" />
    </ng-container>
    } @else {
    <div class="dark:bg-[#303746] w-8 h-8 flex items-center justify-center rounded-full mr-4">
      <lib-hero-icons id="78C04FD1-14A7" class="shadow-alert-success rounded-full bg-transparent"
        *ngIf="alertItem.type === alertTypes.SUCCESS" [size]="5" [iconName]="'success'" />
      <lib-hero-icons id="3E4C88A6-53B3" class="shadow-alert-info rounded-full bg-transparent"
        *ngIf="alertItem.type === alertTypes.INFO" [size]="5" [iconName]="'info'" />
      <lib-hero-icons id="A8228302-9F27" class="shadow-alert-warning rounded-full bg-transparent"
        *ngIf="alertItem.type === alertTypes.WARNING" [size]="5" [iconName]="'warning'" />
      <lib-hero-icons id="5E80C6EB-D1AC" class="shadow-alert-danger rounded-full bg-transparent"
        *ngIf="alertItem.type === alertTypes.DANGER" [size]="5" [iconName]="'danger'" />
    </div>
    }
  }
  <div id="9BE976CE-05B1" class="flex-1 text-black dark:text-white">
    <h3 id="11732311-EBAE" class="text-[17px] font-bold dark:text-white">
      {{ alertItem.title | translate }}
    </h3>
    @if (alertItem.message) {
    <p id="748D0F00-5777" class="dark:text-[#C8C5C5] text-[13px]">{{ alertItem.message | translate }}</p>
    }
    @if (alertItem.mode === alertModes.CONFIRM) {
    <div id="3033294C-3A6C" class="mt-4 flex justify-end">
      <button id="DD0C0867-AE79" (click)="alertStore.cancel(alertItem.id)" type="button"
        class="dark:text-white text-slate-600 hover:opacity-70 relative z[52] rounded-md mr-3.5 px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-0">
        {{ alertItem.textCancelButton || 'ALERT.CANCEL' | translate }}
      </button>
      <button id="DD0C0867-AE79" (click)="alertStore.confirm(alertItem.id)" [ngClass]="{
                'bg-green-600 hover:bg-green-700': alertItem.type === alertTypes.SUCCESS,
                'bg-yellow-500 hover:bg-yellow-600': alertItem.type === alertTypes.WARNING,
                'bg-red-600 hover:bg-red-700': alertItem.type === alertTypes.DANGER,
                'bg-blue-600 hover:bg-blue-700': alertItem.type === alertTypes.INFO
              }" type="button"
        class="text-white focus:ring-0 relative z[52] rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none">
        {{ alertItem.textSuccessButton || 'ALERT.CONFIRM' | translate }}
      </button>
    </div>
    }
    @if (alertItem.mode === alertModes.OPTION) {
    <div class="mt-4 flex justify-end">
      @if (alertItem.textSecondaryButton) {
        <button id="DD0C0867-AE79" (click)="alertStore.selectOption(alertItem.id, 'secondaryButton')" type="button"
          class="dark:text-white text-slate-600 hover:opacity-70 relative z[52] rounded-md mr-3.5 px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-0">
          {{ alertItem.textSecondaryButton | translate }}
        </button>
      }

      @if (alertItem?.textPrimaryButton) {
        <button id="DD0C0867-AE79" (click)="alertStore.selectOption(alertItem.id, 'primaryButton')" type="button"
          class="bg-blue-600 hover:bg-blue-700 text-white focus:ring-0 relative z[52] rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none">
          {{ alertItem.textPrimaryButton | translate }}
        </button>
      }
    </div>
    }
  </div>
  @if(alertItem.mode === alertModes.ALERT){
  <span #bar
    class="left-0 bottom-0 h-0.5 w-full after:content-[''] after:blur-sm after:w-full after:h-0.5 after:block absolute animate-width-min"
    [ngClass]="{
        'bg-[#FFD426] after:bg-[#FFD426]': alertItem.type === alertTypes.WARNING,
        'bg-[#00DF80] after:bg-[#00DF80]': alertItem.type === alertTypes.SUCCESS,
        'bg-[#F04248] after:bg-[#F04248]': alertItem.type === alertTypes.DANGER,
        'bg-[#2196F3] after:bg-[#2196F3]': alertItem.type === alertTypes.INFO
         }"></span>
  }
</div>
}