import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app-services/localStorage.service';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

interface IPushNotificationsState {
  HasPermission: boolean;
  Token: {
    value: string;
  }
};

const initialState: IPushNotificationsState = {
  HasPermission: false,
  Token: {
    value: '',
  }
};

export const PushNotificationsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    router = inject(Router),
    platform = inject(Platform),
    localStorageService = inject(LocalStorageService),
  ) => {

    const addListeners = async (): Promise<void> => {
      await PushNotifications.addListener('registration', Token => {
        console.info('Registration token: ', Token.value)
        patchState(store, { Token });
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        if (notification?.actionId === 'tap' && notification?.notification?.data?.chatType === 'chat') {
          router.navigate(['chat']);
        }
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    }

    const registerNotifications = (): void => {
      PushNotifications.register().then(() => {
        patchState(store, { HasPermission: true });
        localStorageService.setItem('canNotification', 'true');
      });
    }

    const openSetting = async (): Promise<void> => {
      if (platform.is('ios')) {
        await NativeSettings.openIOS({
          option: IOSSettings.App,
        });
      } else {
        await NativeSettings.openAndroid({
          option: AndroidSettings.ApplicationDetails,
        });
      }
    }

    const pauseNotifications = (): void => {
      PushNotifications.unregister().then(() => {
        patchState(store, { HasPermission: false });
        localStorageService.setItem('canNotification', 'false');
      });
    }
    return {
      pauseNotifications,

      resumeNotifications: async () => {
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive !== 'granted') {
          openSetting().then(async () => {
            permStatus = await PushNotifications.checkPermissions();
            if (permStatus.receive === 'granted') registerNotifications();
            else pauseNotifications();
          })
        } else registerNotifications();
      },

      initFmc: (): void => {
        if (Capacitor.getPlatform() !== 'web') {
          platform.ready().then(async () => {
            try {
              await addListeners();

              let permStatus = await PushNotifications.checkPermissions();
              if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') permStatus = await PushNotifications.requestPermissions();
              if (permStatus.receive === 'granted' && !localStorageService.getItem('canNotification')) {
                await PushNotifications.register().then(() => {
                  patchState(store, { HasPermission: true });
                  localStorageService.setItem('canNotification', 'true');
                });
              }

              if (permStatus.receive !== 'granted'){
                patchState(store, { HasPermission: false });
                localStorageService.setItem('canNotification', 'false');
              }

              patchState(store, { HasPermission: localStorageService.getItem('canNotification') === 'true' })

            } catch (error) { }
          });
        }
      },

      resetStore(): void {
        patchState(store, initialState);
      }
    };
  }),
);