import { Component, inject } from "@angular/core";
import { NgClass } from "@angular/common";
import { backdropAnimation } from "src/shared/animations/animations";
import { delay, of } from "rxjs";
import { PaymentsStore } from "@app-payments/data-access/payments.store";
import { ButtonComponent } from "@shared/components/button/button.component";
@Component({
    selector: 'app-payment-error',
    templateUrl: './payment-error.component.html',
    styleUrls: ['./payment-error.component.scss'],
    standalone: true,
    animations: [backdropAnimation],
    imports: [NgClass, ButtonComponent]
})
export class PaymentErrorComponent {
    public readonly paymentsStore = inject(PaymentsStore);

    public finish(): void {
        this.paymentsStore.clearVoucherAndErrorPayment();
        this.delay(300).subscribe(() => {
            this.paymentsStore.removeComponent();
        });
    }

    public delay(ms: number) {
        return of(null).pipe(delay(ms));
    }
}