import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideStore } from '@ngrx/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { FormGroupDirective } from '@angular/forms';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ErrorHandler,
  LOCALE_ID,
  DEFAULT_CURRENCY_CODE,
  isDevMode,
  importProvidersFrom,
  ApplicationConfig,
} from '@angular/core';
import { CustomLoader } from './translate.loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter, Route, Routes,
  withComponentInputBinding,
  withViewTransitions
} from '@angular/router';

import { ErrorHandlerService } from '@application/errors/data-access';
import { AppConfig, AppConfigProvider, devExtremeKey } from '@application/config';
import config from 'devextreme/core/config';
import { provideLottieOptions } from 'ngx-lottie';
import {
  LanguageInterceptor,
  TokenInterceptor,
} from '@application/http-client';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

config({ licenseKey: devExtremeKey });

export function LoaderFactory() {
  return new CustomLoader();
}

export function playerFactory() {
  return import('lottie-web');
}

export const appConfig = (environment: AppConfig, ProvideRouter: Routes | Route[] ): ApplicationConfig => ({
  providers: [
    provideHttpClient(
      withInterceptors([LanguageInterceptor, TokenInterceptor])
    ),
    AppConfigProvider(environment),
    provideStore(),
    provideRouter(
      ProvideRouter,
      withComponentInputBinding(),
      withViewTransitions()
    ),
    provideLottieOptions({
      player: () => playerFactory(),
    }),
    provideAnimations(),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    }),
    importProvidersFrom(LoadingBarHttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        isolate: true,
        defaultLanguage: 'en-US',
        loader: {
          provide: TranslateLoader,
          useFactory: LoaderFactory,
        },
      })
    ),
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    FormGroupDirective,
    provideEnvironmentNgxMask(),
  ],
});
