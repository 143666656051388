import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { HttpTransportType } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { IAuthAccessToken } from '@app-auth/data-access/entities/auth.interface';
import { IChat, IMessage, IMessageToSend } from '@app-chat/data-access/entities/chat.interface';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private http = inject(HttpClient);

  public openConnection(authorizationData: IAuthAccessToken) {
    return new signalR.HubConnectionBuilder().withUrl(url(Protocol.https, EntityPath.chat, '/chat?userId=' + authorizationData.PersonId + '&userTenantId=' + authorizationData.TenantId), {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
      withCredentials: false
    })
      .withAutomaticReconnect([5000, 5000, 5000, 10000])
      .build();
  }

  public getChatInfo(): Observable<IChat> {
    return this.http.get<IChat>(url(Protocol.https, EntityPath.chat, '/Chat/GetMyChatInfo'), {
      withCredentials: true
    });
  }

  public getMessages(params: { chatId: string, skip: number, take: number, lessThanDate: string }): Observable<IMessage[]> {
    return this.http.get<IMessage[]>(url(Protocol.https, EntityPath.chat, '/Message/GetMessages'), {
      params,
      withCredentials: true
    });
  }

  public getHasUnreadMessage(): Observable<Object> {
    return this.http.get(url(Protocol.https, EntityPath.chat, '/Message/HasUnreadMessage'), {
      withCredentials: true
    });
  }

  public uploadMedia(uploadMedia: IMessageToSend & Pick<IMessage, 'ChatId'>): Observable<Object> {
    return this.http.post(url(Protocol.https, EntityPath.chat, '/Message/Upload'), uploadMedia, {
      withCredentials: true,
    });
  }
}
