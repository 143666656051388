import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly actuarPrefixKey = 'treino/';
  public setItem(key: string, value: string): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.setItem(_key, value);
  }

  public getItem(key: string): string | null {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.getItem(_key);
  }

  public removeItem(key: string): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.removeItem(_key);
  }

  public clearAllData(): void {
    const keysToPreserve = ['Email', 'Alarm', 'consentMode'];
    const preservedData: Record<string, string | null> = {};
  
    keysToPreserve.forEach(key => {
      preservedData[key] = this.getItem(key);
    });

    localStorage.clear();

    keysToPreserve.forEach(key => {
      if (preservedData[key] !== null) {
        this.setItem(key, preservedData[key]!);
      }
    });
  }
}
