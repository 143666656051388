import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class CustomLoader implements TranslateLoader {
  private http = inject(HttpClient);

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`/assets/i18n/${lang}.json`);
  }
}
