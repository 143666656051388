@if (paymentsStore.PaymentError(); as paymentError) {
  <section @backdropAnimation class="font-Satoshi px-[18px] flex flex-col items-center justify-center h-full fixed top-0 right-0 left-0 z-40 opacity-0 backdrop-blur-sm ease will-change-auto duration-500"
    [ngClass]="true ? 'visible backdrop-brightness-50 opacity-100': 'invisible opacity-0'">
    <div  class="flex flex-col items-center justify-center w-full max-w-[21rem] p-[24px] text-gray-400 border-[1px] border-[#25344A] rounded-[18px] shadow-8xl bg-[#101928]">
      <div class="flex flex-col justify-center items-center">
        <img class="w-[7.75rem]" src="assets/images/error.gif" alt="error">
        @if (paymentError?.message) {
          <h1 class="text-center font-bold text-title-md leading-[30.8px] text-white">{{ paymentError?.message }}</h1>
        }
        @if (paymentError?.returnedCode) {
          <h3 class="text-center font-medium text-content-md leading-[22.4px] text-content-secondary">Código de retorno: {{ paymentError?.returnedCode }}</h3>
        }

        <app-button (click)="finish()" class="w-full flex mt-[28px]" title="Tentar Novamente"></app-button>
      </div>
    </div>
  </section>
}

