import { Input, inject } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AlertStore, EAlertModes, EAlertTypes, IAlertItem } from '@alert/data-access';
import { HeroIconsComponent } from '@shared/components/ui/hero-icons';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'lib-alert-box',
  templateUrl: './alert-box.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, HeroIconsComponent],
})
export class AlertBoxComponent {
  public readonly alertStore = inject(AlertStore);
  @Input() alertItem: IAlertItem;
  public alertTypes = EAlertTypes;
  public alertModes = EAlertModes;
}
