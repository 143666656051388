import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EntityPath, Protocol, url } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IUserPhoto } from '@shared/components/user-photo/data-access/entities/user-photo.interface';

@Injectable({
  providedIn: 'root'
})
export class UserPhotosService {
  private http = inject(HttpClient);

  public getUserPhotosById(id: string): Observable<IUserPhoto[]> {
    return this.http.get<IUserPhoto[]>(url(Protocol.https, EntityPath.file_group, '/UserPhotos'), {
      params: {
        groupId: id,
        flag: 2
      },
      withCredentials: true
    });
  }

  public postUserPhoto(userPhoto: Partial<IUserPhoto>): Observable<IUserPhoto> {
    return this.http.post<IUserPhoto>(url(Protocol.https, EntityPath.file_group, '/UserPhotos'), userPhoto, {
      withCredentials: true
    });
  }

  public putUserPhoto(userPhoto: IUserPhoto): Observable<IUserPhoto> { 
    return this.http.put<IUserPhoto>(url(Protocol.https, EntityPath.file_group, '/UserPhotos'), userPhoto, {
      withCredentials: true
    });
   }

  public setFlagAsyncToTreino(fileId: string): Observable<void> {
    return this.http.put<void>(url(Protocol.https, EntityPath.file_group, '/UserPhotos/SetFlagAsync'), null, {
      params: {
        fileId,
        flag: 2
      },
      withCredentials: true
    });
  }
}
