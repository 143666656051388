import {
  Component,
  effect,
  ElementRef,
  input,
  model,
  Signal,
  viewChild,
  signal,
  untracked,
  WritableSignal,
  InputSignal,
  ModelSignal,
  OnDestroy,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { backdropAnimation, slideUpAnimation } from '@shared/animations/animations';
import { PresentationStore } from '@app-presentation/data-access/presentation.store';

@Component({
  selector: 'app-slide-up',
  templateUrl: './slide-up.component.html',
  styleUrls: ['./slide-up.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [backdropAnimation, slideUpAnimation]
})
export class SlideUpComponent implements OnDestroy {
  public readonly presentationStore = inject(PresentationStore);
  public readonly slideUp: Signal<ElementRef<HTMLElement> | undefined> = viewChild('slideUp');
  public readonly show: ModelSignal<boolean> = model<boolean>(false);
  public readonly title: InputSignal<string | null> = input<string | null>(null);
  public readonly subtitle: InputSignal<string | null> = input<string | null>(null);
  public readonly dragOffset: WritableSignal<number> = signal<number>(0);
  private readonly startY: WritableSignal<number> = signal(0);
  private readonly slideUpHeight: WritableSignal<number> = signal(0);
  public readonly isDragging: WritableSignal<boolean> = signal(false);
  public readonly id: string = uuidv4();
  
  protected canGoBack = effect(() => {
    const id = this.presentationStore.CanGoBack()?.id;
    if (id) {
      untracked(() => {
        if (id! === this.id) {
          this.show.set(false);
        }
      });
    }
  });

  protected onSlideUpHide = effect(() => {
    if (!this.show()) {
      untracked(() => {
        this.presentationStore.removePriority(this.id);
      });
    }
  });

  ngOnDestroy(): void {
    this.hide();
    this.presentationStore.removePriority(this.id);
  }

  public hide(): void {
    const slideUp = this.slideUp()?.nativeElement;

    if (slideUp && this.show()) {
      document.body.removeChild(slideUp);
      this.show.set(false);
    }
  }

  public startDrag(event: MouseEvent | TouchEvent): void {
    this.isDragging.set(true);
    const clientY = event instanceof MouseEvent 
      ? event.clientY 
      : event.touches[0].clientY;
    
    this.startY.set(clientY);
    this.slideUpHeight.set(event.currentTarget instanceof HTMLElement 
      ? event.currentTarget.offsetHeight 
      : 0);
  }

  public dragging(event: MouseEvent | TouchEvent): void {
    this.isDragging.set(true);
    const clientY = event instanceof MouseEvent 
      ? event.clientY 
      : event.touches[0].clientY;

    const deltaY = Math.max(0, clientY - this.startY());
    this.dragOffset.set(deltaY);
  }

  public onTouchEnd(): void {    
    this.isDragging.set(false);
    const halfHeight = this.slideUpHeight() / 2;
    this.dragOffset() > halfHeight ? this.show.set(false) : this.dragOffset.set(0);
  }

  public clear(): void {        
    this.dragOffset.set(0);
    this.startY.set(0);
    this.slideUpHeight.set(0);
  }

  private observeShowSlideUp = effect(() => {
    const slideUp = this.slideUp()?.nativeElement;

    if (slideUp && this.show()) {
      untracked(() => {
        this.clear()
        this.presentationStore.registerPriority(this.id, 2);
        document.body.appendChild(slideUp);
      })
    }
  });
}