@if (iconType === 'regular') {
  @switch (brandCardName) {
    @case ('Mastercard') { <img @fadeSwitch class="h-5" src="assets/images/mastercard-card.svg" alt="mastercard"> }
    @case ('Master') { <img @fadeSwitch class="h-5" src="assets/images/mastercard-card.svg" alt="mastercard"> }
    @case ('Visa') { <img @fadeSwitch class="h-5" src="assets/images/visa.svg" alt="visa"> }
    @case ('Elo') { <img @fadeSwitch class="h-5" src="assets/images/elo-card.svg" alt="elo"> }
    @case ('Amex') { <img @fadeSwitch class="w-full" src="assets/images/amex.svg" alt="amex"> }
    @case ('Diners') { <img @fadeSwitch class="h-5" src="assets/images/diners-card.svg" alt="diners"> }
    @case ('Hipercard') { <img @fadeSwitch class="h-5" src="assets/images/hipercard.svg" alt="hipercard"> }
  }
}

@if (iconType === 'card') {
  @switch (brandCardName) {
    @case ('Master') { <img @fadeSwitch class="h-10" src="assets/images/mastercard.svg" alt="mastercard"> }
    @case ('Mastercard') { <img @fadeSwitch class="h-10" src="assets/images/mastercard.svg" alt="mastercard"> }
    @case ('Visa') { <img @fadeSwitch class="h-12" src="assets/images/visa.svg" alt="visa"> }
    @case ('Elo') { <img @fadeSwitch class="h-10" src="assets/images/elo.svg" alt="elo"> }
    @case ('Amex') { <img @fadeSwitch class="h-28" src="assets/images/amex.svg" alt="amex"> }
    @case ('Diners') { <img @fadeSwitch class="h-12" src="assets/images/diners.svg" alt="diners"> }
    @case ('Hipercard') { <img @fadeSwitch class="h-10" src="assets/images/hipercard.svg" alt="hipercard"> }
  }
}