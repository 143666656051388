import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { WorkoutService } from '@app-training/data-access/infra/workout.service';
import { catchError, iif, of, pipe, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '@app-services/localStorage.service';
import { DateTime } from 'luxon';
import { IExercise, ISeriesLoadProgression, ITimeValues, IUpdateCurrentLoad, IUpdateLoad, IWorkout, IWorkoutItem, IWorkoutPlan, IWorkoutWeekLog } from '@app-training/data-access/entities/workouts.interface';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import Timer, { TimeCounter } from 'easytimer.js';
import { Router } from '@angular/router';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
import { HapticsStore } from '@shared/stores/haptics.store';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

declare const gtag: Function;
interface IWorkoutState {
    ActiveWorkoutPlan: IWorkoutPlan | null;
    SelectedWorkout: IWorkout | null;
    WorkoutOfTheDay: IWorkout | null;
    Exercises: Record<string, IExercise>;
    WorkoutTimer: Record<string, Timer>;
    WorkoutWeekLog: IWorkoutWeekLog[] | null;
};

const initialState: IWorkoutState = {
    ActiveWorkoutPlan: null,
    SelectedWorkout: null,
    WorkoutOfTheDay: null,
    Exercises: {},
    WorkoutTimer: {},
    WorkoutWeekLog: null
};

export const WorkoutStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        workoutService = inject(WorkoutService),
        localStorageService = inject(LocalStorageService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        hapticsStore = inject(HapticsStore),
        router = inject(Router),
    ) => {
        const updateSetItems = (workout: IWorkout) => {
            const setItemsBySet: Record<number, IWorkoutItem[]> = {};
            workout.TrainingItems.forEach(workoutItem => {
                workoutItem.SetOrder = workoutItem.SetOrder || 1;
                workoutItem.SeriesPerformeds = workoutItem.SeriesPerformeds || 0;
                if (!workoutItem.SeriesLoadsProgressions?.length) {
                    workoutItem.SeriesLoadsProgressions = [{
                        Repetition: '',
                        Load: '',
                        SeriesLoadProgressionId: '',
                        Time: '',
                        Velocity: '',
                        CurrentSerie: 1,
                        TrainingItemId: workoutItem.TrainingItemId
                    }]
                }
                if (workoutItem.Set) {
                    if (setItemsBySet[workoutItem.Set]) {
                        const firstItem = setItemsBySet[workoutItem.Set][0];
                        if (workoutItem.SeriesLoadsProgressions?.length !== firstItem.SeriesLoadsProgressions?.length) {
                            workoutItem.SeriesLoadsProgressions = firstItem.SeriesLoadsProgressions?.map((serieFirstItem, index) => {
                                const serie = workoutItem.SeriesLoadsProgressions?.[index];
                                if (serie) return serie;
                                const newSerie: ISeriesLoadProgression = {
                                    Repetition: '',
                                    Load: '',
                                    SeriesLoadProgressionId: '',
                                    Time: '',
                                    Velocity: '',
                                    CurrentSerie: serieFirstItem.CurrentSerie,
                                    TrainingItemId: serieFirstItem.TrainingItemId
                                }
                                return newSerie;
                            });
                        }
                        setItemsBySet[workoutItem.Set].push(workoutItem)
                    } else {
                        setItemsBySet[workoutItem.Set] = [workoutItem];
                    }
                }
            });
            workout.SetItems = setItemsBySet;
            return workout;
        };

        const alreadyTrainedToday = (ActiveWorkoutPlan: IWorkoutPlan): boolean => {
            return !!ActiveWorkoutPlan.Trainings.find(workout => {
                const today = DateTime.now().endOf('day');

                const lastPerfomedOn = DateTime.fromISO(workout.LastPerfomedOn).endOf('day');
                const diffLastPerfomedOn = today.diff(lastPerfomedOn, 'days').days;

                const LastPrintedOn = DateTime.fromISO(workout.LastPrintedOn).endOf('day');
                const diffLastPrintedOn = today.diff(LastPrintedOn, 'days').days;

                return diffLastPrintedOn === 0 || diffLastPerfomedOn === 0;
            });
        }

        const reordingWorkouts = () => {
            const ActiveWorkoutPlan = store.ActiveWorkoutPlan();
            if (!ActiveWorkoutPlan) return;
            ActiveWorkoutPlan.Trainings = ActiveWorkoutPlan.Trainings.sort((a, b) => {
                if (!a.LastPerfomedOn && !a.LastPrintedOn) return -1;

                const aLastPerfomedOn = DateTime.fromISO(a.LastPerfomedOn);
                const bLastPerfomedOn = DateTime.fromISO(b.LastPerfomedOn);

                const aLastPrintedOn = DateTime.fromISO(a.LastPrintedOn);
                const bLastPrintedOn = DateTime.fromISO(b.LastPrintedOn);

                const aDateTime = aLastPerfomedOn > aLastPrintedOn ? aLastPerfomedOn : aLastPrintedOn;
                const bDateTime = bLastPerfomedOn > bLastPrintedOn ? bLastPerfomedOn : bLastPrintedOn;

                return aDateTime < bDateTime ? -1 : 1;
            });
            patchState(store, { ActiveWorkoutPlan, ...setPropLoaded('ActiveWorkoutPlan') });
        }

        const startWorkout = rxMethod<string>(pipe(
            tap(() => patchState(store, { ...setPropLoading('startWorkout') })),
            switchMap((trainingId) => {
                return workoutService.startWorkout(trainingId).pipe(
                    tap(() => {
                        patchState(store, { ...setPropLoaded('startWorkout') });
                    }),
                    catchError((error) => {
                        patchState(store, { ...setPropError('startWorkout') });
                        errorHandlerService.handleError(error);
                        return of(error);
                    })
                )
            })
        ))

        const removeTimeById = (id: string) => {
            const localTimeValuesStr = localStorageService.getItem('workout-time-values');
            const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
            delete localTimeValuesObj[id];
            localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
        }

        const stopWorkoutTimer = (id: string) => {
            store.WorkoutTimer()[id]?.stop();
            const localTimeValuesStr = localStorageService.getItem('workout-time-values');
            const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
            delete localTimeValuesObj[id];
            localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
        }

        return {
            getWorkoutPlan: rxMethod<void>(pipe(
                switchMap(() => {
                    const authorization = JSON.parse(localStorageService!.getItem('authorization')!);
                    const personId = authorization?.PersonId;
                    if (!personId) return of(null);
                    return workoutService.getWorkoutPlanActiveByPersonId(personId).pipe(
                        tap((ActiveWorkoutPlan) => {
                            if (ActiveWorkoutPlan) {
                                ActiveWorkoutPlan.Trainings = ActiveWorkoutPlan.Trainings.map(training => {
                                    training = updateSetItems(training);

                                    if (!training.isWorkoutStarted) {
                                        const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                                        const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                                        const workoutTime = localTimeValuesObj[training.TrainingId] as ITimeValues;

                                        const exerciseHasMinDuration = workoutTime?.timeValues?.minutes >= 2;
                                        const completedExercises = training.TrainingItems.filter(item => item.SeriesPerformeds >= item.SeriesLoadsProgressions.length);

                                        training.isWorkoutStarted = (exerciseHasMinDuration && completedExercises.length >= 1) || completedExercises.length >= 2;

                                        if (!training.isWorkoutStarted) {
                                            removeTimeById(training.TrainingId);
                                        }
                                    }
                                    return training;
                                });
                                reordingWorkouts();
                                const WorkoutOfTheDay = alreadyTrainedToday(ActiveWorkoutPlan) ? null : ActiveWorkoutPlan.Trainings.find(training => training.TrainingId === ActiveWorkoutPlan.WorkoutOfTheDayId);
                                patchState(store, { WorkoutOfTheDay });
                            }
                            patchState(store, { ActiveWorkoutPlan, ...setPropLoaded('ActiveWorkoutPlan') });
                        }),
                        catchError((error) => {
                            patchState(store, { ActiveWorkoutPlan: null, ...setPropError('ActiveWorkoutPlan') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                }
                )
            )),

            selectWorkout: (id: string) => {
                const workout = store.ActiveWorkoutPlan()?.Trainings.find(training => training.TrainingId === id);
                patchState(store, { SelectedWorkout: workout });
                return workout;
            },

            updateSeriesPerformed: rxMethod<{ workoutItem: IWorkoutItem, seriesPerformed: number }>(pipe(
                tap(({ workoutItem, seriesPerformed }) => {
                    workoutItem.SeriesPerformeds = seriesPerformed
                    const { Series, SeriesPerformeds } = workoutItem;

                    if (SeriesPerformeds === Series) {
                        hapticsStore.triggerImpact(ImpactStyle.Light);
                    }

                    const workout = store.SelectedWorkout();

                    if (workout && !workout.isWorkoutStarted) {
                        const exerciseHasMinDuration = store.WorkoutTimer()[workout.TrainingId].getTimeValues().minutes >= 2;
                        const completedExercises = workout.TrainingItems.filter(item => item.SeriesPerformeds >= item.SeriesLoadsProgressions.length);

                        if (completedExercises && ((exerciseHasMinDuration && completedExercises.length >= 1) || completedExercises.length >= 2)) {
                            startWorkout(workout.TrainingId);
                            workout.isWorkoutStarted = true;
                        }

                        patchState(store, { SelectedWorkout: workout });
                    }
                }),
                switchMap(({ workoutItem, seriesPerformed }) => workoutService.updateSeriesPerformed(workoutItem.TrainingItemId, seriesPerformed)),
            )),

            getExerciseById: rxMethod<string>(pipe(
                switchMap((exerciseId) => iif(
                    () => !store.Exercises()[exerciseId],
                    workoutService.getExerciseById(exerciseId).pipe(
                        tap((exercise) => {
                            patchState(store, { Exercises: { ...store.Exercises(), [exerciseId]: exercise } });
                        }),
                        catchError((error) => {
                            patchState(store, { ActiveWorkoutPlan: null, ...setPropError('ActiveWorkoutPlan') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    ),
                    of(null)
                ))
            )),

            updateLoad: rxMethod<IUpdateLoad[]>(pipe(
                tap(() => patchState(store, { ...setPropLoading('UpdateCharge') })),
                switchMap((payload) => {
                    return workoutService.updateLoad(payload).pipe(
                        tap(() => {
                            const workout = store.SelectedWorkout();

                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === payload[0].TrainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesLoadsProgressions = workoutItem.SeriesLoadsProgressions?.map(serie => {
                                        const correspondingPayloadItem = payload.find(item => item.SeriesLoadProgressionId === serie.SeriesLoadProgressionId);
                                        if (correspondingPayloadItem) {
                                            serie.Load = correspondingPayloadItem.Load;
                                        }
                                        return serie;
                                    });

                                    patchState(store, { SelectedWorkout: workout, ...setPropLoaded('UpdateCharge') });
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('finishWorkout') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            updateCurrentLoad: rxMethod<IUpdateCurrentLoad[]>(pipe(
              tap(() => patchState(store, { ...setPropLoading('UpdateCharge') })),
              switchMap((payload) => {
                  return workoutService.updateCurrentLoad(payload).pipe(
                      tap(() => {
                          const workout = store.SelectedWorkout();

                          if (workout) {
                              const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === payload[0].TrainingItemId);
                              if (workoutItem) {
                                  workoutItem.SeriesLoadsProgressions = workoutItem.SeriesLoadsProgressions?.map(serie => {
                                      const correspondingPayloadItem = payload.find(item => item.SeriesLoadProgressionId === serie.SeriesLoadProgressionId);
                                      if (correspondingPayloadItem) {
                                          serie.CurrentLoad = correspondingPayloadItem.CurrentLoad;
                                      }
                                      return serie;
                                  });
                                  console.log(workout)
                                  patchState(store, { SelectedWorkout: workout, ...setPropLoaded('UpdateCharge') });
                              }
                          }
                      }),
                      catchError((error) => {
                          patchState(store, { ...setPropError('finishWorkout') });
                          errorHandlerService.handleError(error);
                          return of(error);
                      })
                  )
              })
          )),

            startWorkoutTimer: (key: string) => {
                gtag('event', 'iniciar_treino');
                const timer = store.WorkoutTimer()[key] ?? new Timer();
                const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                timer.removeAllEventListeners();

                timer.on('minutesUpdated', () => {
                    const workout = store.SelectedWorkout();
                    if (workout && !workout.isWorkoutStarted) {
                        const exerciseHasMinDuration = timer.getTimeValues().minutes >= 2;
                        const completedExercises = workout.TrainingItems.filter(item => item.SeriesPerformeds >= item.SeriesLoadsProgressions.length);

                        if (completedExercises && ((exerciseHasMinDuration && completedExercises.length >= 1) || completedExercises.length >= 2)) {
                            startWorkout(workout.TrainingId);
                            workout.isWorkoutStarted = true;
                        }
                    }
                });

                timer.on('secondsUpdated', () => {
                    localTimeValuesObj[key] = { timeValues: timer.getTimeValues(), updateOn: DateTime.now().toISO() };
                    localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
                });

                timer.start({ startValues: localTimeValuesObj[key]?.timeValues || {} });

                patchState(store, { WorkoutTimer: { ...store.WorkoutTimer, [key]: timer } });
            },

            pauseWorkoutTimer: (id: string) => {
                store.WorkoutTimer()[id]?.pause();
            },

            stopWorkoutTimer,

            removeOldTimeValues: () => {
                const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                const newLocalTimeValuesObj = Object.entries<{ timeValues: TimeCounter, updateOn: string }>(localTimeValuesObj).reduce((acc, [key, value]) => {
                    if (DateTime.fromISO(value.updateOn).plus({ hours: 24 }) < DateTime.now()) {
                        return acc;
                    }
                    return { ...acc, [key]: value };
                }, {});
                localStorageService.setItem('workout-time-values', JSON.stringify(newLocalTimeValuesObj));
            },

            loadingActiveWorkoutPlan: () => {
                patchState(store, { ...setPropLoading('ActiveWorkoutPlan') })
            },

            finishWorkout: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('finishWorkout') })),
                switchMap((trainingId) => {
                    return workoutService.finishWorkout(trainingId).pipe(
                        tap((response) => {
                            hapticsStore.triggerImpact(ImpactStyle.Heavy);
                            alertStore.openAlert({
                                title: 'Treino concluído!',
                                mode: 'alert',
                                message: `O treino de ${store.SelectedWorkout()?.Name || `Treino ${store.SelectedWorkout()?.Flag}`} acaba de ser concluído. Continue para o próximo treino.`,
                                type: EAlertTypes.SUCCESS
                            });
                            const ActiveWorkoutPlan = store.ActiveWorkoutPlan();
                            ActiveWorkoutPlan?.Trainings.map(training => {
                                if (training.TrainingId === trainingId) {
                                    training.LastPerfomedOn = DateTime.now().toISO();
                                    training.TrainingItems = training.TrainingItems.map(item => ({ ...item, SeriesPerformeds: 0 }));
                                    training.isWorkoutStarted = false;
                                }
                                return training;
                            })

                            if (ActiveWorkoutPlan && response?.SessionsWorkoutPlanPerformed) {
                                ActiveWorkoutPlan!.SessionsWorkoutPlanPerformed = response.SessionsWorkoutPlanPerformed;
                            }

                            const WorkoutWeekLog = store.WorkoutWeekLog();
                            WorkoutWeekLog?.forEach(log => {
                                if (log.Day === DateTime.now().toISODate()) log.Trained = true;
                            });

                            patchState(store, { ActiveWorkoutPlan, WorkoutWeekLog });
                            reordingWorkouts();
                            patchState(store, { WorkoutOfTheDay: null, ...setPropLoaded('finishWorkout') });
                            router.navigate(['/training']);
                            stopWorkoutTimer(trainingId);
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('finishWorkout') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            clearActiveWorkoutPlan: () => {
                patchState(store, { ActiveWorkoutPlan: null }, setPropInit('ActiveWorkoutPlan'));
            },

            getWeekLogByPersonId: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('WorkoutWeekLog') })),
                switchMap((personId) => {
                    return workoutService.getWeekLogByPersonId(personId).pipe(
                        tap((response) => {
                            patchState(store, { WorkoutWeekLog: response, ...setPropLoaded('WorkoutWeekLog') });
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('WorkoutWeekLog') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            shouldRefreshActiveWorkout(): boolean {
                const lastRefresh = localStorageService.getItem('LastRefresh');
                if (!lastRefresh) return true;

                const lastRefreshDate = new Date(lastRefresh);
                const currentDate = new Date();


                return (
                    lastRefreshDate.getFullYear() !== currentDate.getFullYear() ||
                    lastRefreshDate.getMonth() !== currentDate.getMonth() ||
                    lastRefreshDate.getDate() !== currentDate.getDate()
                );
            },

            activeWorkoutRefreshed(): void {
                const currentDate = new Date();
                localStorageService.setItem('LastRefresh', `${currentDate}`);
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('ActiveWorkoutPlan', 'UpdateCharge', 'finishWorkout', 'startWorkout', 'WorkoutWeekLog'));
            }
        };
    }),
    withReqState('ActiveWorkoutPlan', 'UpdateCharge', 'finishWorkout', 'startWorkout', 'WorkoutWeekLog'),
);
