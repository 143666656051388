import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LoadingModuleScreenComponent } from '@shared/components/ui/loading-screen';
import { AlertComponent } from '@alert/feature';


@Component({
  selector: 'lib-presentation-ui',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoadingModuleScreenComponent, AlertComponent],
  templateUrl: './presentation-ui.component.html',
  styleUrl: './presentation-ui.component.scss',
})
export class PresentationUiComponent {

}
