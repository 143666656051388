import {
  AppConfig,
  AppSource,
  LocalAppUrl,
  LocalEnv,
} from '@application/config';

export const environment: AppConfig = {
  appSource: AppSource.Treino,
  environment: 'local',
  apiUrl: LocalEnv,
  version: '1.0.0',
  appUrl: LocalAppUrl,
};
