import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityPath, Protocol, url } from '@environments/environment';
import { Observable } from 'rxjs';
import { IPost } from '@app-feed/data-access/entities/post.interface';

@Injectable({
  providedIn: 'root',
})
export class FeedService {

  constructor() { }
  private http = inject(HttpClient);

  public getFeed(params: { take: number, skip: number }): Observable<IPost[]> {
    return this.http.get<IPost[]>(url(Protocol.https, EntityPath.post_service, '/Post/GetAllActivePostsPagined'), {
      params,
      withCredentials: true
    });
  }

}
