import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityPath, Protocol, url } from '@environments/environment';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { IODataFrequency } from '@app-frequency/data-access/entities/frequency.interface';

@Injectable({
  providedIn: 'root',
})
export class FrequencyService {

  constructor() { }
  private http = inject(HttpClient);

  public getFrequency(date: DateTime, personId: string): Observable<IODataFrequency> {
    return this.http.get<IODataFrequency>(url(Protocol.https, EntityPath.odata, '/Acessos'), {
      params: {
        $orderby: 'DataEntrada desc,DataSaida desc',
        $select: 'DataEntrada,HoraEntrada,DataSaida,HoraSaida,AcessoId,PessoaId',
        $filter: `PessoaId eq ${personId} and ((DataEntrada ge ${date.startOf('month').toUTC()}) and (DataEntrada le ${date.endOf('month').toUTC()}))`
      },
      withCredentials: true
    });
  }
}
