@if (show()) {
  <section 
    (click)="show.set(false)"
    [@backdropAnimation]
    #slideUp
    class="fixed flex items-end font-Satoshi h-screen w-screen top-0 right-0 left-0 bottom-0 z-[99999] transition-all ease will-change-auto duration-500 backdrop-brightness-50 backdrop-blur-[0.156rem]"
  >
    <div
      [@slideUpAnimation]
      class="h-[90vh] w-full flex justify-center items-end"
    >
      <section 
        (click)="$event.stopPropagation()"
        (touchstart)="startDrag($event)"
        (touchmove)="dragging($event)"
        (touchend)="onTouchEnd()"
        [ngClass]="{' transition-transform duration-300 ease-out': !isDragging()}"
        class="flex flex-col gap-[24px] max-h-full relative rounded-t-xl will-change-transform w-full sm:max-w-default min-w-[300px] px-[18px] pt-[24px] pb-[32px] bg-background-secondary shadow-8xl text-gray-400 border-[0.063rem] border-solid border-background-secondary"
        [style.transform]="'translateY(' + dragOffset() + 'px)'"
      >
        <hr class="bg-[#FFFFFF] opacity-5 absolute top-0 left-1/2 -translate-x-1/2 shrink-0 h-[5px] w-[38px] border-none mt-[5px] rounded-xl">
        @if (title() || subtitle()) {
          <div class="w-full flex justify-center items-center">
            <div class="w-full">
              <h1 class="font-Satoshi font-bold text-title-lg leading-[36.4px] text-white text-center">
                {{ title() }}
              </h1>
              @if (subtitle()) {
              <h2 class="font-Satoshi font-medium leading-[25.6px] text-title-sm text-title-secondary text-center mt-[8px]">
                {{ subtitle() }}
              </h2>
              }
            </div>
          </div>
        }
        <div (touchstart)="$event.stopPropagation()"(touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()" class="flex-1 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-rounded-md scrollbar-track-rounded-md scrollbar-thumb-[#283850] scrollbar-track-[#090f18]">
          <ng-content></ng-content>
        </div>
      </section>
    </div>
  </section>
}