import { Component, DestroyRef, effect, inject, model, ModelSignal, signal, untracked, WritableSignal } from "@angular/core";
import { TimersComponent } from '@app-timers/feature/timers.component';
import { ETimerId } from '@app-timers/data-access/entities/timers.enum';
import { counterInOutAnimation } from "@shared/animations/animations";
import { IconComponent } from "@shared/components/icon/icon.component";
import { TimersStore } from '@app-timers/data-access/timers.store';
import { timer } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-modal-timer',
    templateUrl: './modal-timer.component.html',
    styleUrls: ['./modal-timer.component.scss'],
    standalone: true,
    imports: [
        TimersComponent,
        IconComponent
    ],
    animations: [counterInOutAnimation]
}) export class ModalTimerComponent {
    private readonly destroyRef = inject(DestroyRef);
    public readonly timersStore = inject(TimersStore);
    public readonly timerId: ModelSignal<ETimerId> = model<ETimerId>(ETimerId.AMRAP);
    public readonly displayTimer: WritableSignal<boolean> = signal<boolean>(true);

    public hide(): void {
        this.displayTimer.set(false);
        const animationDuration = 200;
        timer(animationDuration).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.timersStore.removeModalTimer()
        });
    }
}